import React from "react";
import { Row, Col, Typography, Radio } from "antd";
import FormInput from "components/FormItems/FormInput";
import AppButton from "components/AppButton/AppButton";
import GroupLogo from "assets/images/common/group.png";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { inject, observer } from "mobx-react";
import FormSelectInput from "components/FormItems/FormSelectInput";

const GroupForm = ({ setStep, app, onSuccess = () => {}, onBack = () => {}, integration, groupStore }) => {
  const { setData, activeCampFilter } = integration;
  const { group } = groupStore;

  // const validate = values => {
  //   const errors = {};

  //   if (app === "ac" && values.filter === "by" && !values.listid && !values.tagid && !values.status) {
  //     errors.listid = "You must select at least one: List ID or Tag ID or Status";
  //     errors.tagid = "You must select at least one: List ID or Tag ID or Status";
  //     errors.status = "You must select at least one: List ID or Tag ID or Status";
  //   }

  //   return errors;
  // };

  return (
    <Formik
      enableReinitialize
      initialValues={{ name: "", zohoType: "", filter: "", tagid: "", listid: "", status: "-1" }}
      validationSchema={Yup.object().shape({
        name: Yup.string().when([], {
          is: () => !group,
          then: Yup.string()
            .min(1, "Name should be at least 1 character long")
            .required("Group name must be provided")
        }),
        zohoType: Yup.string().when([], {
          is: () => app === "zoho",
          then: Yup.string().required("Type must be provided")
        }),
        filter: Yup.string().when([], {
          is: () => app === "ac",
          then: Yup.string().required("You must select an option")
        }),
        status: Yup.string().when([], {
          is: () => app === "ac",
          then: Yup.string().required("Status must be provided")
        })
      })}
      onSubmit={(values, { setSubmitting }) => {
        if (app === "zoho") {
          setData("zohoType", values?.zohoType);
        }

        setStep(3);
        setData("groupName", values.name);
        onSuccess(values);
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, handleChange, setFieldValue, isSubmitting }) => {
        return (
          <Form className="pt-3">
            <Row type="flex" justify="center">
              <Col span={24} className="text-center mb-3">
                <img src={GroupLogo} alt="group" />
                {/* <p className="text-muted text-dark fs-6 mt-3">Create your new group in which we import synced contacts.</p> */}
              </Col>
              {!group && (
                <Col xs={24} md={16} className="text-center">
                  <FormInput placeholder="Enter Group Name" name="name" value={values.name} onChange={handleChange} touched={touched.name} error={errors.name} />
                </Col>
              )}
              {app === "zoho" && (
                <Col xs={24} md={16} className="text-center">
                  <FormSelectInput
                    allowClear
                    name="zohoType"
                    value={values.zohoType}
                    error={errors.zohoType}
                    touched={touched.zohoType}
                    containerClassnames="mb-0"
                    selectEleClassnames="w-100"
                    placeholder="Choose CRM type"
                    onChange={e => setFieldValue("zohoType", e)}
                    options={[
                      { label: "Contacts", value: "Contacts" },
                      { label: "Leads", value: "Leads" }
                    ]}
                  />
                </Col>
              )}
              {app === "ac" && (
                <>
                  <Col span={24} className="text-center">
                    <h5 className="mb-2 fw-bold">Import Contact</h5>
                    <Radio.Group
                      className="w-100 text-wrap"
                      onChange={event => {
                        setFieldValue("filter", event.target.value);
                      }}
                      value={values.filter}
                      name="filter"
                      error={errors.filter}
                      touched={touched.filter}
                    >
                      <Radio className="mb-3 text-wrap" value="all">
                        All
                      </Radio>
                      <Radio className="mb-3 text-wrap" value="by">
                        By
                      </Radio>
                    </Radio.Group>
                    {errors?.filter && touched?.filter && <div className="text-danger-color">{errors.filter}</div>}
                  </Col>
                  {values?.filter === "by" && (
                    <Col xs={24} md={16} className="text-center">
                      <FormSelectInput
                        allowClear
                        name="status"
                        value={values.status}
                        error={errors.status}
                        touched={touched.status}
                        containerClassnames="mb-3"
                        selectEleClassnames="w-100"
                        placeholder="Select Status"
                        onChange={e => setFieldValue("status", e)}
                        options={activeCampFilter?.status}
                      />
                      <FormSelectInput
                        allowClear
                        name="tagid"
                        value={values.tagid}
                        error={errors.tagid}
                        touched={touched.tagid}
                        containerClassnames="mb-3"
                        selectEleClassnames="w-100"
                        placeholder="Select Tag"
                        onChange={e => setFieldValue("tagid", e)}
                        options={activeCampFilter?.tag}
                      />
                      <FormSelectInput
                        allowClear
                        name="listid"
                        value={values.listid}
                        error={errors.listid}
                        touched={touched.listid}
                        containerClassnames="mb-3"
                        selectEleClassnames="w-100"
                        placeholder="Select List"
                        onChange={e => setFieldValue("listid", e)}
                        options={activeCampFilter?.list}
                      />
                    </Col>
                  )}
                </>
              )}
              <Col span={24} className="vh-center gap-3 mt-3 text-center">
                <AppButton label={"Previous"} light withoutBg isSubmitting={isSubmitting} prefixIcon={<i className="bx bx-left-arrow-alt me-1" />} onClick={onBack} />
                <AppButton label={"Next"} type="submit" light isSubmitting={isSubmitting} postfixIcon={<i className="bx bx-right-arrow-alt ms-1" />} />
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default inject(stores => ({
  groupStore: stores.store.groupStore,
  integration: stores.store.integrationStore
}))(observer(GroupForm));
