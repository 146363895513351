import { runInAction, decorate, observable } from "mobx";
import {
  getOptOutInformation,
  updateIsBlockedByUser
} from "service/optOutService";

class ObservableOptOutStore {
  updateBlockStatusResponse = {};

  updateUserBlockStatus = async args => {
    const updateBlockStatusResponse = await updateIsBlockedByUser(args);
    try {
      runInAction(() => {
        this.updateBlockStatusResponse = updateBlockStatusResponse.data;
      });
    } catch (error) {
      runInAction(() => {
        this.updateBlockStatusResponse = error;
      });
    }
    return this.updateBlockStatusResponse;
  };
  getOptOutInformation = async code => {
    const updateBlockStatusResponse = await getOptOutInformation(code);
    try {
      runInAction(() => {
        this.updateBlockStatusResponse = updateBlockStatusResponse.data;
      });
    } catch (error) {
      runInAction(() => {
        this.updateBlockStatusResponse = error;
      });
    }
    return this.updateBlockStatusResponse;
  };
}

decorate(ObservableOptOutStore, {});

export default new ObservableOptOutStore();
