import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Row, Col, Typography, Card } from "antd";
import Bundles from "components/BuyCredits/Bundles";
// import CustomBundle from "components/BuyCredits/CustomBundle";
import BundleType from "components/BuyCredits/BundleType";
import AlertModal from "components/Modals/AlertModal";
import { useHistory, useLocation } from "react-router-dom";
import Offer from "components/BuyCredits/SpecialOffer";
import UpgradeBanner from "components/TrialUser/UpgradeBanner";

const fontColor = {
  color: "#565656"
};

const PurchaseGateway = props => {
  const { modalStore, authStore, userStore } = props;

  const { user } = authStore;
  const {
    fetchMySpecialPlans,
    specialPlan: plan,
    setCreditPackage
  } = userStore;

  const history = useHistory();
  const location = useLocation();

  const [lessQuantityAlert, setLessQuantityAlert] = useState(false);

  useEffect(() => {
    fetchMySpecialPlans();
  }, []);

  useEffect(() => {
    if (
      authStore?.user?.profileType === "postpaid" ||
      authStore?.user?.role === "subaccount"
    )
      history.push("/");
  }, []);

  if (authStore?.user?.role === "trial") return <UpgradeBanner />;

  const handleBuyNow = values => {
    const { sms, mms } = values;
    if (
      (modalStore.isMms ? !mms : !sms) ||
      (modalStore.isMms ? mms : sms) < (modalStore.isMms ? 42 : 300) ||
      (modalStore.isMms ? mms : sms) > (modalStore.isMms ? 20000 : 250000) ||
      (values.specialPlan && (sms < 500 || sms > 250000))
    ) {
      setLessQuantityAlert(true);
    } else {
      setCreditPackage(values);
      history.push("/buy-credits/checkout", {
        from: location?.state?.from || "/invoice"
      });
    }
  };

  if (!user) return null;

  return (
    <>
      <Card
        className={`my-3 w-100 rounded-xl bg-white shadow mx-auto p-3`}
        bordered={false}
      >
        <Row gutter={[16, 16]} type="flex" justify="center">
          <Col span={24}>
            <Typography.Title level={4} className="text-info-color">
              Buy Credits
            </Typography.Title>
          </Col>
          {plan ? (
            <Offer
              centsPerCredit={plan.centsPerCredit}
              handleBuyNow={handleBuyNow}
            />
          ) : (
            <>
              <BundleType />
              <Col
                md={20}
                lg={modalStore.quickMessage ? 24 : 18}
                xl={16}
                span={24}
              >
                <Bundles
                  fontColor={fontColor}
                  handleBuyNow={handleBuyNow}
                  isFirstBuy={false}
                />
              </Col>
            </>
          )}
          {/* <Col md={12} span={24}>
            <CustomBundle
              handleBuyNow={handleBuyNow}
              isFirstBuy={false}
              fontColor={fontColor}
            />
          </Col> */}
        </Row>
      </Card>
      <AlertModal
        visible={lessQuantityAlert}
        title={`You can not buy this amount of ${
          modalStore.isMms ? "MMS" : "SMS"
        }`}
        description="Please enter quantity again to buy."
        primaryBtnProps={{ onClick: () => setLessQuantityAlert(false) }}
        error
        onCancel={() => setLessQuantityAlert(false)}
      />
    </>
  );
};

export default inject(stores => ({
  authStore: stores.store.authStore,
  modalStore: stores.store.modalStore,
  supportStore: stores.store.supportStore,
  userStore: stores.store.userStore
}))(observer(PurchaseGateway));
