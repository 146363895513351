import openSocket from "socket.io-client";
import {
  SOCKET_URL,
  MESSAGE_NEW,
  PHONE_UPDATE,
  ROOM_NEW,
  USER_UPDATE,
  GATEWAY_MESSAGE_STATUS_UPDATE,
  GATEWAY_MESSAGE_CREATE,
  GROUP_PARTICIPANTS_UPDATE,
  GROUP_STATUS_UPDATE,
  USER_BALANCE_UPDATE,
  GATEWAY_MMS_STATUS_UPDATE,
  GATEWAY_MMS_CREATE,
  GATEWAY_MESSAGE_DELETE,
  GATWAY_REPLY
} from "constant/apiService";
import { cookie } from "utils/cookie";
import { AUTH_TOKEN, AUTH_USER_BALANCE, AUTH_USER_INFO } from "constant/constant";

export const socket = openSocket(SOCKET_URL, {
  query: `token=${cookie.get(AUTH_TOKEN)}`,
  secure: false,
  jsonp: false
});

socket.on("error", data => {});
socket.on("reconnect_error", error => {});
socket.on("reconnect_attempt", attempt => {});
socket.on("connect", () => console.log("⚡ Sockets Connected ⚡"));
socket.on("disconnect", () => console.log("🔴 Sockets Disconnected 🔴"));

class Socket {
  constructor({ state }) {
    this.io = null;
    this.state = state;
    this.startSocket();
  }

  startSocket() {
    socket.on(USER_UPDATE, user => {
      user = JSON.parse(user);
      console.log("USER_UPDATE", user);
      this.state.authStore.user = user;
      cookie.set(AUTH_USER_INFO, user);
    });

    socket.on(USER_BALANCE_UPDATE, data => {
      data = JSON.parse(data);
      console.log("USER_BALANCE_UPDATE", data);
      this.state.authStore.balanceObj = data;
      cookie.set(AUTH_USER_BALANCE, data);
    });

    // socket.on(MESSAGE_NEW, message => {
    //   // console.log("MESSAGE_NEW", message);
    //   this.state.messageStore.addMessage(message);
    // });
    // socket.on(ROOM_NEW, message => {
    //   // console.log("ROOM_NEW", message);
    //   this.state.roomStore.addRoom(message);
    // });
    // socket.on(GATEWAY_MESSAGE_DELETE, message => {
    //   const data = JSON.parse(message);
    //   console.log(data);
    //   if (data.isMMS) {
    //     this.state.gatewayMmsStore.deleteMessageStatus(data);
    //   } else {
    //     this.state.gatewayStore.deleteMessageStatus(data);
    //   }
    // });
    // socket.on(PHONE_UPDATE, message => {
    //   // console.log("PHONE_UPDATE", message);
    //   this.state.phoneStore.updateRealTimeMobileDetail(message);
    // });

    // socket.on(GATEWAY_MESSAGE_STATUS_UPDATE, statusObj => {
    //   console.log("GATEWAY_MESSAGE_STATUS_UPDATE", statusObj);
    //   const data = JSON.parse(statusObj);
    //   if (data.isMMS) {
    //     this.state.gatewayMmsStore.updateMessageStatus(data);
    //   } else {
    //     this.state.gatewayStore.updateMessageStatus(data);
    //   }
    // });
    // socket.on(GATEWAY_MMS_STATUS_UPDATE, statusObj => {
    //   console.log("GATEWAY_MMS_STATUS_UPDATE", statusObj);
    //   const data = JSON.parse(statusObj);
    //   this.state.gatewayMmsStore.updateMessageStatus(data);
    // });
    socket.on(GATWAY_REPLY, messageObj => {
      messageObj = JSON.parse(messageObj);
      this.state.gatewayStore.newMessageEvent(messageObj);
      this.state.messageStore.newMessageEvent(messageObj);
      this.state.authStore.setuserNotificationList(messageObj);
      this.state.authStore.setnewUserNotification(true);
    });
    // socket.on(GATEWAY_MMS_CREATE, messageObj => {
    //   console.log("GATEWAY_MMS_CREATE", messageObj);
    //   messageObj = JSON.parse(messageObj);
    //   this.state.gatewayMmsStore.newMessageEvent(messageObj);
    //   this.state.authStore.setuserNotificationList(messageObj);
    //   this.state.authStore.setnewUserNotification(true);
    // });

    // socket.on(GROUP_PARTICIPANTS_UPDATE, groupId => {
    //   //console.log(groupId);
    //   this.state.groupStore.updateGroupParticipants(JSON.parse(groupId));
    // });

    // socket.on(GROUP_STATUS_UPDATE, data => {
    //   data = JSON.parse(data);
    //   // console.log(data)
    //   const { _id, totalContacts, status, errorLog } = data;
    //   this.state.groupStore.updateGroupStatus({
    //     _id,
    //     totalContacts,
    //     status,
    //     errorLog
    //   });
    // });
  }
}

export default Socket;
