import { get, post, put, deleteRequest } from "utils/api";

// invite user
export const inviteUser = async data => {
  const response = await post(`/user/inviteLink`, data);
  return response.data;
};

// accept invite
export const acceptInvite = async (token, data) => {
  const response = await post(`/auth/inviteToken/${token}`, data);
  return response.data;
};

//  get all sub accounts list
export const getAllSubAccounts = async params => {
  const response = await get("/user/inviteLink", params);
  return response.data.data;
};

// remove sub account
export const removeSubAccount = async email => {
  const response = await post(`/user/delete-sub-account`, email);
  return response.data;
};

// modify sub account permission
export const modifyAccountPermissions = async data => {
  const response = await post(`/user/change-access`, data);
  return response.data;
};

// Assign Group to Sub Account
export const assignGroupToSubAccount = async data => {
  const response = await post(`/group/assignGroup`, data);
  return response.data;
};

// Assign Template to Sub Account
export const assignTemplateToSubAccount = async data => {
  const response = await post(`/template/assignTemplate`, data);
  return response.data;
};

// Assign Saved Bulk to Sub Account
export const assignSavedBulkToSubAccount = async data => {
  const response = await post(`/draftcampaign/assign`, data);
  return response.data;
};

// update sub account feature permissions
export const updateRolePermission = async data => {
  const response = await put(`/user/update-role-access`, data);
  return response.data;
};

export const loginAsSubAccount = async data => {
  const response = await post(`/user/subaccountLogin`, data);
  return response.data.data;
};

export const checkEmailExists = async email => {
  const response = await post(`/user/check-invite-link`, email);
  return response.data;
};
