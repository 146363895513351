import { get, post, put } from "utils/api";

/**
 * Call get all Contacts api
 * @returns {Promise<any>}
 */

export const getAllContacts = async params => {
  const getAllContactsResponse = await get("/contact", params);
  return getAllContactsResponse.data;
};

export const updateBlockStatus = async (number, params = {}) => {
  const updateBlockStatusResponse = await put(
    `/contact/block/${number}`,
    params
  );
  return updateBlockStatusResponse.data;
};

/**
 * Add new bulk api
 * @param bulkData
 * @returns {Promise<any>}
 */

export const updateBulkBlockStatus = async (bulkData = {}) => {
  const updateBulkBlockStatusResponse = await post(
    "/contact/bulkblock",
    bulkData,
    true,
    {
      "Content-Type": `multipart/form-data`
    }
  );
  return updateBulkBlockStatusResponse.data;
};

export const createContact = async data => {
  const response = await post("/contact", data);
  return response.data;
};

// Get signle contact
export const getContact = async number => {
  const response = await get(`/contact/contactInformation/${number}`);
  return response.data;
};

// get contact by name or number
export const getContactBySearch = async (params = {}) => {
  const response = await get(`/contact/searchcontact`, params);
  return response.data;
};

// Export contacts
export const getExportContacts = async params => {
  const response = await get(`/contact/getAlloptout`, params);
  return response.data;
};
