import * as mobx from "mobx";
import moment from "moment";
import React from "react";
import ReactGA from "react-ga";

// export const SchedulerTypes = [
//   { label: "Once", value: "once" },
//   { label: "Daily", value: "day" },
//   { label: "Weekly", value: "week" },
//   { label: "Fortnightly", value: "fortnight" },
//   { label: "Monthly", value: "month" },
//   { label: "Recurrence", value: "recurrence" }
// ];
// export const RecurrenceTypes = [
//   { label: "Minute", value: "minute" },
//   { label: "Hour", value: "hour" }
// ];
export const RecurrenceValue = recurrenceTypes => {
  if (recurrenceTypes === "minute") {
    return Array(59)
      .fill()
      .map((_, i) => ({ label: i + 1, value: i + 1 }));
  } else if (recurrenceTypes === "hour") {
    return Array(23)
      .fill()
      .map((_, i) => ({ label: i + 1, value: i + 1 }));
  }
};

export const SortMobile = (devices, medium) => {
  const sortOrder = {
    on: 1,
    busy: 2,
    off: 3
  };
  return mobx
    .toJS(devices)
    .map(device => {
      return device;
    })
    .sort(function(a, b) {
      return sortOrder[a.connectStatus] - sortOrder[b.connectStatus];
    });
};

/**
 *
 * @param category
 * @param action
 * @param label
 * @param value
 * @param nonInteraction
 * @constructor
 */
export const GAEvent = (
  category,
  action,
  label,
  value = "",
  nonInteraction = false
) => {
  // ReactGA.event({ category, action, label, value, nonInteraction });
};

/**
 * Author: Legasov
 */

export const SchedulerTypes = [
  { label: "Once", value: "once" },
  { label: "Recurrence", value: "recurrence" }
];

export const RecurrenceTypes = [
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" }
];

export const RecurrenceWeeklyIntervals = [
  { label: "Every Week", value: 1 },
  { label: "Every Two Weeks", value: 2 }
];

export const RecurrenceMonthlyIntervals = [
  { label: "Every Month", value: 1 },
  { label: "Every Two Months", value: 2 }
];

export const WeekDays = [
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
  { label: "Sunday", value: 7 }
];

export const YearlyWeeks = [
  { label: "Jan, 1st Week", value: 1 },
  { label: "Jan, 2nd Week", value: 2 },
  { label: "Jan, 3rd Week", value: 3 },
  { label: "Jan, 4th Week", value: 4 },
  { label: "Jan, Last Week", value: 5 },
  { label: "Feb, 1st Week", value: 6 },
  { label: "Feb, 2nd Week", value: 7 },
  { label: "Feb, 3rd Week", value: 8 },
  { label: "Feb, Last Week", value: 9 },
  { label: "Mar, 1st Week", value: 10 },
  { label: "Mar, 2nd Week", value: 11 },
  { label: "Mar, 3rd Week", value: 12 },
  { label: "Mar, Last Week", value: 13 },
  { label: "Apr, 1st Week", value: 14 },
  { label: "Apr, 2nd Week", value: 15 },
  { label: "Apr, 3rd Week", value: 16 },
  { label: "Apr, 4th Week", value: 17 },
  { label: "Apr, Last Week", value: 18 },
  { label: "May, 1st Week", value: 19 },
  { label: "May, 2nd Week", value: 20 },
  { label: "May, 3rd Week", value: 21 },
  { label: "May, Last Week", value: 22 },
  { label: "Jun, 1st Week", value: 23 },
  { label: "Jun, 2nd Week", value: 24 },
  { label: "Jun, 3rd Week", value: 25 },
  { label: "Jun, Last Week", value: 26 },
  { label: "Jul, 1st Week", value: 27 },
  { label: "Jul, 2nd Week", value: 28 },
  { label: "Jul, 3rd Week", value: 29 },
  { label: "Jul, 4th Week", value: 30 },
  { label: "Jul, Last Week", value: 31 },
  { label: "Aug, 1st Week", value: 32 },
  { label: "Aug, 2nd Week", value: 33 },
  { label: "Aug, 3rd Week", value: 34 },
  { label: "Aug, Last Week", value: 35 },
  { label: "Sep, 1st Week", value: 36 },
  { label: "Sep, 2nd Week", value: 37 },
  { label: "Sep, 3rd Week", value: 38 },
  { label: "Sep, 4th Week", value: 39 },
  { label: "Sep, Last Week", value: 40 },
  { label: "Oct, 1st Week", value: 41 },
  { label: "Oct, 2nd Week", value: 42 },
  { label: "Oct, 3rd Week", value: 43 },
  { label: "Oct, Last Week", value: 44 },
  { label: "Nov, 1st Week", value: 45 },
  { label: "Nov, 2nd Week", value: 46 },
  { label: "Nov, 3rd Week", value: 47 },
  { label: "Nov, Last Week", value: 48 },
  { label: "Dec, 1st Week", value: 49 },
  { label: "Dec, 2nd Week", value: 50 },
  { label: "Dec, 3rd Week", value: 51 },
  { label: "Dec, 4th Week", value: 52 },
  { label: "Dec, Last Week", value: 53 }
];

export const Months = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 }
];

export const MonthDays = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
  { label: "13", value: 13 },
  { label: "14", value: 14 },
  { label: "15", value: 15 },
  { label: "16", value: 16 },
  { label: "17", value: 17 },
  { label: "18", value: 18 },
  { label: "19", value: 19 },
  { label: "20", value: 20 },
  { label: "21", value: 21 },
  { label: "22", value: 22 },
  { label: "23", value: 23 },
  { label: "24", value: 24 },
  { label: "25", value: 25 },
  { label: "26", value: 26 },
  { label: "27", value: 27 },
  { label: "28", value: 28 },
  { label: "29", value: 29 },
  { label: "30", value: 30 },
  { label: "31", value: 31 }
];

export const smsCampaignHeaders = [
  { label: "Campaign", key: "Campaign" },
  { label: "Contact", key: "Receiver" },
  { label: "Message", key: "Message" },
  { label: "Name", key: "ReceiverName" },
  { label: "Status", key: "Status" },
  { label: "SendTime", key: "SendTime" },
  { label: "DeliveredTime", key: "DeliveredTime" }
];

export const smsClickHeaders = [
  { label: "Number", key: "number" },
  { label: "Clicks", key: "click" },
  { label: "City", key: "city" },
  { label: "Country", key: "country_name" },
  { label: "Platform", key: "platform" }
];

export const fileSchedulerHeaders = [
  "mobile-number",
  "name-optional",
  "message-optional",
  "time",
  "type",
  "recurrence-type",
  "interval",
  "end-date"
];

export const supportedTimezones = [
  { option: "ACST|UTC +9:30/+10:30", timezone: "Australia/Adelaide" },
  { option: "AEST|UTC +10:00/+11:00", timezone: "Australia/Sydney" },
  { option: "AWST|UTC +8:45", timezone: "Australia/Eucla" },
  { option: "IST|UTC +5:30", timezone: "Asia/Kolkata" }
];

export const fileSchedulerProfiles = [
  "dental",
  "common",
  "medical bp",
  "doctor-bp",
  "praktika"
];

export const contactLimit = [
  { label: "5K", value: 5000 },
  { label: "10K", value: 10000 },
  { label: "20K", value: 20000 },
  { label: "30K", value: 30000 },
  { label: "50K", value: 50000 },
  { label: "80K", value: 80000 },
  { label: "100K", value: 100000 }
];

export const creditLimit = [
  { label: "20", value: 20 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "200", value: 200 }
];

export const messageStatus = [
  { label: "All", value: "all", color: "hotpink" },
  { label: "Sent", value: "sent", color: "steelblue" },
  { label: "Delivered", value: "delivered", color: "limegreen" },
  { label: "Queued", value: "queued", color: "coral" },
  { label: "Failed", value: "failed", color: "darkred" },
  { label: "Blocked", value: "blocked", color: "crimson" },
  { label: "Expired", value: "expired", color: "crimson" },
  { label: "Cancelled", value: "cancel", color: "crimson" },
  { label: "Not Approved", value: "reject", color: "salmon" },
  { label: "Other", value: "other", color: "hotpink" },
  { label: "Aborted", value: "aborted", color: "indigo" },
  { label: "Cancelled Schedule", value: "cancel_schedule", color: "purple" },
  { label: "Received", value: "received", color: "deepskyblue" },
  { label: "Unknown", value: "unknown", color: "royalblue" },
  { label: "Waiting Approval", value: "pending", color: "royalblue" },
  { label: "Completed", value: "complete", color: "forestgreen" },
  { label: "Deleted", value: "deleted", color: "olive" },
  { label: "Scheduled", value: "schduled", color: "khaki" },
  { label: "Scheduled", value: "scheduled", color: "khaki" },
  { label: "Dispatched", value: "dispatched", color: "gold" },
  { label: "Preparing", value: "preparing", color: "darkorange" }
];

export const messageTypes = [
  { label: "SMS", value: "sms" },
  { label: "MMS", value: "mms" }
];

export const themeColors = [
  "#bae0ff",
  "#91caff",
  "#69b1ff",
  "#4096ff",
  "#1677ff"
];

export const nonThemeColors = [
  "#f759ab", // magenta
  "#9254de", // purple
  "#36cfc9", // cyan
  "#bae637", // lime
  "#ffec3d", // yellow
  "#ffc53d", // gold
  "#ffa940", // orange
  "#ff7a45" // volcano
];

export const months = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 }
];

// list of years using moment.js
export const years = [
  {
    label: moment()
      .subtract(3, "year")
      .format("YYYY"),
    value: moment()
      .subtract(3, "year")
      .format("YYYY")
  },
  {
    label: moment()
      .subtract(2, "year")
      .format("YYYY"),
    value: moment()
      .subtract(2, "year")
      .format("YYYY")
  },
  {
    label: moment()
      .subtract(1, "year")
      .format("YYYY"),
    value: moment()
      .subtract(1, "year")
      .format("YYYY")
  },
  { label: moment().format("YYYY"), value: moment().format("YYYY") }
];

export const navMenuConfig = [
  {
    label: "Dashboard",
    path: "/",
    icon: <i className="fas fs-5 fa-border-all" />,
    roles: ["user", "admin", "superAdmin", "guest", "subaccount", "trial"],
    submenu: false,
    otherpath: ["/dashboard", "/profile", "/security", "/settings"]
  },
  {
    label: "Campaign",
    path: "/campaign",
    icon: <i className="fas fs-5 fa-inbox" />,
    roles: ["user", "admin", "superAdmin", "guest", "trial"],
    access: ["sms", "mms"],
    submenu: false,
    otherpath: [
      "/mms/campaign/report",
      "/campaign/create",
      "/campaign/update",
      "/campaign/report"
    ]
  },
  {
    label: "Messaging",
    path: "/quick-message",
    icon: <i className="fas fs-5 fa-sms" />,
    access: ["sms", "mms"],
    roles: ["user", "admin", "superAdmin", "guest", "subaccount", "trial"],
    otherpath: [
      "/scheduler",
      "/file-scheduler-form",
      "/email-sms",
      "/inbox-messaging"
    ],
    submenu: [
      {
        label: "New Message",
        path: "/quick-message",
        access: ["sms", "mms"],
        roles: ["user", "admin", "superAdmin", "guest", "subaccount", "trial"]
      },
      {
        label: "File Scheduler",
        path: "/filescheduler",
        access: ["fileScheduler"],
        roles: ["user", "admin", "superAdmin"]
      },
      {
        label: "Email to SMS",
        path: "/email-sms",
        access: ["emailSms"],
        roles: ["user", "admin", "superAdmin"]
      },
      {
        label: "Inbox Messaging",
        path: "/inbox-messaging",
        access: ["sms"],
        roles: ["user", "admin", "superAdmin", "guest", "subaccount", "trial"]
      }
    ]
  },
  {
    label: "History",
    path: "/history",
    icon: <i className="fas fs-5 fa-history" />,
    access: ["history"],
    roles: ["user", "admin", "superAdmin", "guest", "trial"],
    submenu: false
  },
  {
    label: "Template",
    path: "/template",
    icon: <i className="fas fs-5 fa-file-alt" />,
    access: ["template"],
    roles: ["user", "admin", "superAdmin", "guest", "trial"],
    otherpath: ["/template/add", "/template/edit/:id"],
    submenu: false
  },
  {
    label: "Audience",
    path: "/group",
    icon: <i className="fas fs-5 fa-users" />,
    access: ["group"],
    roles: ["user", "admin", "superAdmin", "guest", "trial"],
    otherpath: ["/opt-out", "/medical-contacts"],
    submenu: [
      {
        label: "Group",
        path: "/group",
        access: ["group"],
        roles: ["user", "admin", "superAdmin", "guest", "trial"]
      },
      {
        label: "Opt Out",
        path: "/opt-out",
        roles: ["user", "admin", "superAdmin", "guest", "trial"]
      },
      {
        label: "Medical Contacts",
        path: "/medical-contacts",
        roles: ["superAdmin"]
      }
    ]
  },
  {
    label: "Buy Credits",
    path: "/buy-credits",
    icon: <i className="fas fs-5 fa-credit-card" />,
    roles: ["user", "admin", "superAdmin"],
    submenu: false
  },
  {
    label: "Sender IDs",
    path: "/sender-ids",
    icon: <i className="fas fs-5 fa-id-card" />,
    roles: ["user", "admin", "superAdmin", "guest", "subaccount", "trial"],
    submenu: false
  },
  {
    label: "Buy Numbers",
    path: "/dedicated-numbers",
    icon: <i className="fas fs-5 fa-phone-alt" />,
    roles: ["user", "admin", "superAdmin"],
    submenu: false
  },
  {
    label: "Integration",
    path: "/integrations",
    icon: <i className="fas fs-5 fa-plug" />,
    roles: ["user", "admin", "superAdmin"],
    submenu: false
  },
  {
    label: "Automation",
    path: "/automation/contact-form",
    icon: <i className="fas fs-5 fa-robot" />,
    access: ["automation"],
    roles: ["user", "admin", "superAdmin", "guest", "trial"],
    otherpath: [
      "/automation/contact-form/add-edit",
      "/automation/workflow",
      "/automation/workflow/:id"
    ],
    submenu: [
      {
        label: "Contact Form",
        path: "/automation/contact-form",
        access: ["automation"],
        roles: ["user", "admin", "superAdmin", "guest", "trial"]
      },
      {
        label: "Workflow Actions",
        path: "/automation/workflow",
        access: ["automation"],
        roles: ["user", "admin", "superAdmin", "guest", "trial"]
      }
    ]
  },
  {
    label: "Verification",
    path: "/user-verification",
    icon: <i className="fas fs-5 fa-check-circle" />,
    roles: ["user", "admin", "superAdmin", "guest", "trial"],
    submenu: false
  },
  {
    label: "Sub-Accounts",
    path: "/sub-accounts",
    icon: <i className="fas fs-5 fa-user-friends" />,
    roles: ["user", "admin", "superAdmin", "guest", "trial"],
    submenu: false
  },
  {
    label: "Admin Panel",
    path: "/admin/dashboard",
    icon: <i className="fas fs-5 fa-user-cog" />,
    roles: ["superAdmin"],
    otherpath: [
      "/admin/user-template",
      "/admin/device-information",
      "/admin/users",
      "/admin/user-verification",
      "/admin/approval",
      "/admin/shared-numbers",
      "/admin/business-names",
      "/admin/dedicated-numbers",
      "/admin/param",
      "/admin/monitor"
    ],
    submenu: [
      {
        label: "Dashboard",
        path: "/admin/dashboard",
        roles: ["superAdmin"]
      },

      {
        label: "Users",
        path: "/admin/users",
        roles: ["superAdmin"]
      },
      {
        label: "User Verifications",
        path: "/admin/user-verification",
        roles: ["superAdmin"]
      },
      {
        label: "Message Approvals",
        path: "/admin/approval",
        roles: ["superAdmin"]
      },
      {
        label: "Shared Numbers",
        path: "/admin/shared-numbers",
        roles: ["superAdmin"]
      },
      {
        label: "Business Names",
        path: "/admin/business-names",
        roles: ["superAdmin"]
      },
      {
        label: "Dedicated Number",
        path: "/admin/dedicated-numbers",
        roles: ["superAdmin"]
      },
      {
        label: "System Param",
        path: "/admin/param",
        roles: ["superAdmin"]
      },
      {
        label: "Montoring",
        path: "/admin/monitor",
        roles: ["superAdmin"]
      }
      // {
      //   label: "System Usage",
      //   path: "/admin/usage"
      // },
      // {
      //   label: "Deactive Users",
      //   path: "/admin/deactive-users",
      // },
    ]
  }
];

export const senderTypes = [
  {
    type: "shared",
    description:
      "A random number suitable for 2-way SMS. Replies may be missed if received after a week."
  },
  {
    type: "dedicated",
    description:
      "A consistent number for 2-way SMS, perfect for automations and workflows."
  },
  {
    type: "business",
    description: "Optimal for one-way SMS and branding."
  },
  {
    type: "personal",
    description: "For those desiring replies directly to their mobile."
  }
];

export const forwardReplyConditions = [
  { label: "Contains", value: "contains" },
  { label: "Not Contains", value: "notContains" },
  { label: "Starts With", value: "startsWith" },
  { label: "Not Starts With", value: "notStartsWith" },
  { label: "Any Message", value: "any" }
];
