import MergeLabelsForm from "components/TargetGroup/GroupForms/MergeLabelsForm";
import SpinnerLoader from "components/AppLoader/SpinnerLoader";
import React, { Fragment, useEffect, useState } from "react";
import AppButton from "components/AppButton/AppButton";
import { inject, observer } from "mobx-react";
import { Col, Row } from "antd";

const HubspotApp = ({ groupStore, integration, setStep, onSuccess }) => {
  const { hubspotFields, groupName, importContacts } = integration;
  const { setGroupData, group } = groupStore;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    hubspotFields()
      .then(res => {
        if (res.length > 0)
          setGroupData({
            headers: res.map((_, i) => ({
              id: `hub-${i}`,
              newLabel: _?.label,
              oldLabel: _?.name,
              type: _?.fieldType
            }))
          });
        setLoading(false);
      })
      .catch(err => {
        setStep(1);
      });
  }, []);

  const onMergeLabels = (values, reset) => {
    values.name = groupName;
    delete values.headers;
    if (group) {
      values.name = group?.name;
      values.groupid = group?._id;
    }

    importContacts("hubspot", values)
      .then(res => {
        if (res?.status) {
          onSuccess(res.data);
        }
      })
      .finally(() => {
        reset();
      });
  };

  return (
    <Row type="flex" justify="center" className="h-100">
      <Col span={24} className="my-3 h-100">
        {loading ? (
          <div className="text-center m-5 vh-center gap-3 fw-bold pt-5">
            <SpinnerLoader />
            Fetching Hubspot fields...
          </div>
        ) : (
          <MergeLabelsForm
            connectApp
            onSuccess={onMergeLabels}
            defaultLabel={[
              {
                id: 3,
                newLabel: "Email",
                oldLabel: ""
              }
            ]}
            footerProps={
              <Col className="align-self-end">
                <AppButton label={"Previous"} light withoutBg prefixIcon={<i className="bx bx-left-arrow-alt me-1" />} onClick={() => setStep(1)} />
              </Col>
            }
          />
        )}
      </Col>
    </Row>
  );
};

export default inject(stores => ({
  integration: stores.store.integrationStore,
  modalStore: stores.store.modalStore,
  groupStore: stores.store.groupStore
}))(observer(HubspotApp));
