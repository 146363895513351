import { runInAction, decorate, observable } from "mobx";
import {
  getAllGroups,
  addGroup,
  addContacts,
  updateGroup,
  deleteGroup,
  getGroupById,
  getGroupContactsById,
  getGroupFields,
  addGroupWithContact,
  moveContactsToGroup,
  addSingleContact,
  updateSingleContact,
  deleteSingleContact,
  deleteMultipleContact,
  getSingleContact,
  addPraktikaGroup,
  getExportGroupContacts
} from "service/groupService";
import _ from "lodash";
import * as mobx from "mobx";

class ObservableGroupStore {
  group = undefined;
  quickGroup = false;
  groupList = [];
  groupData = undefined;
  selectedGroup = undefined;
  addGroupResponse = undefined;
  updateGroupResponse = undefined;
  deleteGroupResponse = undefined;
  selectedGroupFields = [];
  initialSingleContactValues = {};
  blocked = 0;
  pageSize = 10;
  current = 1;
  search = null;
  totalPages = 1;
  total = 0;
  isMobile = false;
  sortField = "createdAt";
  sortOrder = "descend";

  groupDataContactList = [];
  groupDataPageSize = 10;
  groupDataCurrent = 1;
  groupDataSearch = null;
  groupDataTotalPages = 0;
  groupDataTotal = 0;
  groupDatasortField = "createdAt";
  groupDatasortOrder = "descend";

  contact = undefined;
  exportResponse = {};

  selectedContacts = [];
  selectedGroupIds = [];
  selectedGroupObj = [];

  // for handling campaign's selected number (number selection view only)
  setSelectedContacts = (items, ids) => {
    runInAction(() => {
      if (!items) {
        this.selectedContacts = [];
        this.selectedGroupIds = [];
      }
      if (items?.length > 0) {
        if (ids) this.selectedGroupIds = ids;
        const list = _.uniqBy([...items, ...this.selectedContacts], "number");
        this.selectedContacts = list.filter(_ => {
          if (this.selectedGroupIds.includes(_?.id)) {
            return _;
          }
        });
      } else {
        this.selectedContacts = [];
        this.selectedGroupIds = [];
      }
    });
  };

  // for handling campaign's selected groupObj (group selection view only)
  setSelectedGroups = (items, ids = []) => {
    runInAction(() => {
      if ((ids?.length > 0, Array.isArray(items))) {
        const list = [...items, ...this.selectedGroupObj];
        const filtered = list.filter(obj => ids.includes(obj._id));
        this.selectedGroupObj = _.unionBy(filtered, "_id");
      } else {
        this.selectedGroupObj = [];
      }
    });
  };

  changeSorting = fieldObj => {
    runInAction(() => {
      const { field, sortOrder } = fieldObj;
      this.sortField = field;
      this.sortOrder = sortOrder;
    });
  };

  setSearch = search => {
    runInAction(() => {
      this.search = search;
      this.current = 1;
    });
  };

  setPage = current => {
    runInAction(() => {
      this.current = current;
    });
  };

  setPageSize = pageSize => {
    runInAction(() => {
      this.pageSize = pageSize;
      this.current = 1;
    });
  };

  setGroupData = groupData => {
    runInAction(() => {
      this.groupData = groupData ? groupData : undefined;
    });
  };

  setResponse = (key, value) => {
    runInAction(() => {
      this[key] = value;
    });
  };

  // get all groups
  getAllGroupList = async (isAdd, withoutLimit) => {
    const params = {
      page: isAdd ? this.current + 1 : this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      noPaging: withoutLimit ? "true" : "false",
      limit: withoutLimit ? undefined : this.pageSize
    };
    if (this.search) params.search = this.search;
    const data = await getAllGroups(params);
    try {
      runInAction(() => {
        this.groupList = isAdd ? _.uniq(_.concat(mobx.toJS(this.groupList), data.items || data), "_id") : _.uniq(data.items || data, "_id");
        this.selectedGroup = undefined;
        this.total = data.total;
        this.totalPages = data.totalPages;
        this.current = data.current;
        this.pageSize = data.limit;
      });
    } catch (error) {
      runInAction(() => {
        this.groupList = "error";
      });
    }
    return this.groupList;
  };

  // Add new group
  addGroup = async groupData => {
    const addGroupResponse = await addGroup(groupData);
    try {
      runInAction(() => {
        this.addGroupResponse = addGroupResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.addGroupResponse = error;
      });
    }
    return this.addGroupResponse;
  };

  //select group data
  selectGroup = groupData => {
    this.selectedGroup = groupData ? JSON.parse(JSON.stringify(groupData)) : undefined;
  };

  // add bulk contacts to group
  addContacts = async (groupData, groupId) => {
    const updateGroupResponse = await addContacts(groupData, groupId);
    try {
      runInAction(() => {
        this.updateGroupResponse = updateGroupResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.updateGroupResponse = error;
      });
    }
    return this.updateGroupResponse;
  };

  //update group details
  updateGroup = async (groupData, groupId) => {
    const updateGroupResponse = await updateGroup(groupData, groupId);
    try {
      runInAction(() => {
        this.updateGroupResponse = updateGroupResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.updateGroupResponse = error;
      });
    }
    return this.updateGroupResponse;
  };

  // Delete Group
  deleteGroup = async groupId => {
    const deleteGroupResponse = await deleteGroup(groupId);
    try {
      runInAction(() => {
        this.deleteGroupResponse = deleteGroupResponse;
        this.selectedGroup = undefined;
        this.getAllGroupList();
      });
    } catch (error) {
      runInAction(() => {
        this.deleteGroupResponse = error;
      });
    }
    return this.deleteGroupResponse;
  };

  // get group fields by id
  getSelectedGroupFields = async groupId => {
    const groupFieldsResponse = await getGroupFields(groupId);
    try {
      runInAction(() => {
        this.selectedGroupFields = groupFieldsResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
    return this.selectedGroupFields;
  };

  //add new group with contacts id
  addGroupWithContact = async groupData => {
    const addGroupResponse = await addGroupWithContact(groupData);
    try {
      runInAction(() => {
        this.addGroupResponse = addGroupResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.addGroupResponse = error;
      });
    }
    return this.addGroupResponse;
  };

  moveContactsToGroup = async (contactIds, groupId) => {
    const addGroupResponse = await moveContactsToGroup(contactIds, groupId);
    try {
      runInAction(() => {
        this.addGroupResponse = addGroupResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.addGroupResponse = error;
      });
    }
    return this.addGroupResponse;
  };

  // get group data by id
  getGroupById = async groupId => {
    const groupByIdResponse = await getGroupById(groupId);
    try {
      runInAction(() => {
        this.group = groupByIdResponse.data;
      });
    } catch (error) {
      runInAction(() => {
        this.group = error;
      });
    }
    return this.group;
  };

  changeGroupDataSorting = fieldObj => {
    runInAction(() => {
      const { field, sortOrder } = fieldObj;
      this.groupDatasortField = field;
      this.groupDatasortOrder = sortOrder;
    });
  };

  setGroupDataPage = current => {
    runInAction(() => {
      this.groupDataCurrent = current;
    });
  };

  setGroupDataSearch = search => {
    runInAction(() => {
      this.groupDataSearch = search;
      this.groupDataCurrent = 1;
    });
  };

  setGroupDataPageSize = pageSize => {
    runInAction(() => {
      this.groupDataPageSize = pageSize;
      this.groupDataCurrent = 1;
    });
  };

  // get group contacts by id
  getAllGroupDataList = async id => {
    const params = {
      page: this.groupDataCurrent,
      limit: this.groupDataPageSize,
      sortField: this.groupDatasortField,
      sortOrder: this.groupDatasortOrder
    };
    if (this.groupDataSearch) params.search = this.groupDataSearch;
    const data = await getGroupContactsById(params, id);
    try {
      runInAction(() => {
        this.groupDataContactList = data.items;
        this.groupDataCurrent = data.current;
        this.groupDataTotal = data.total;
        this.groupDataTotalPages = data.totalPages;
        this.groupDataPageSize = data.limit;
        this.blocked = data.unsubscribe;
      });
    } catch (error) {
      runInAction(() => {
        this.groupDataContactList = error;
      });
    }
    return this.groupDataContactList;
  };

  // add single contact to group
  addSingleContact = async (data, groupid) => {
    const addSingleContactResponse = await addSingleContact(data, groupid);
    try {
      runInAction(() => {
        this.addSingleContactResponse = addSingleContactResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.addSingleContactResponse = error;
      });
    }
    return this.addSingleContactResponse;
  };

  // remove single contact from group
  deleteSingleContact = async contactId => {
    const deleteSingleContactResponse = await deleteSingleContact(contactId);
    try {
      runInAction(() => {
        this.deleteSingleContactResponse = deleteSingleContactResponse;
        this.getAllGroupDataList(this.group?._id);
      });
    } catch (error) {
      runInAction(() => {
        this.deleteSingleContactResponse = error;
      });
    }
    return this.deleteSingleContactResponse;
  };

  // delete multiple contacts from group
  deleteMultipleContact = async data => {
    const deleteMultipleContactResponse = await deleteMultipleContact(data);
    try {
      runInAction(() => {
        this.deleteMultipleContactResponse = deleteMultipleContactResponse;
        this.groupDataCurrent =
          this.groupDataCurrent - (data.contact_ids.length / this.groupDataPageSize).toFixed(0) == 0 ? 1 : this.groupDataCurrent - (data.contact_ids.length / this.groupDataPageSize).toFixed(0);
        this.getAllGroupDataList(this.group?._id);
      });
    } catch (error) {
      runInAction(() => {
        this.deleteMultipleContactResponse = error;
      });
    }
    return this.deleteMultipleContactResponse;
  };

  // update single contact
  updateSingleContact = async (data, groupId, contactId) => {
    const updateSingleContactResponse = await updateSingleContact(data, groupId, contactId);
    try {
      runInAction(() => {
        this.updateSingleContactResponse = updateSingleContactResponse;
        this.groupDataContactList = this.groupDataContactList.map(item => {
          if (item._id == contactId) {
            console.log(item._id, contactId);
            return updateSingleContactResponse.data.updatedGroupContact;
          } else {
            return item;
          }
        });
      });
    } catch (error) {
      runInAction(() => {
        this.updateSingleContactResponse = error;
      });
    }
    return this.updateSingleContactResponse;
  };

  // get single contact data by id
  initialContactValue = async contactid => {
    const initialSingleContactRespose = await getSingleContact(contactid);
    try {
      runInAction(() => {
        initialSingleContactRespose[0].fields.map(field => {
          this.initialSingleContactValues[field.name] = field.value;
          return this.initialSingleContactValues;
        });
      });
    } catch (error) {
      runInAction(() => {
        this.initialSingleContactValues = error;
      });
    }
    return this.initialSingleContactValues;
  };

  // update contacts count in group
  updateGroupParticipants = (groupId, value = 1) => {
    try {
      runInAction(() => {
        let index = this.groupList.findIndex(({ _id }) => _id == groupId);
        if (index > -1) {
          let temp = this.groupList[index];
          if (isNaN(temp.totalContacts)) temp.totalContacts = 0;
          temp.totalContacts += value;
          this.groupList[index] = temp;
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  // update group status
  updateGroupStatus = ({ _id: groupId, totalContacts, status, errorLog }) => {
    try {
      runInAction(() => {
        let index = this.groupList.findIndex(({ _id }) => _id == groupId);
        if (index > -1) {
          let temp = this.groupList[index];
          temp.totalContacts = totalContacts;
          temp.status = status;
          temp.errorLog = errorLog;
          this.groupList[index] = temp;
          //if selected group matches ,then update it
        }
        if (this.group && this.group._id === groupId) {
          this.group.errorLog = errorLog;
          this.group.status = status;
          this.group.totalContacts = totalContacts;
          this.getAllGroupDataList(this.group?._id);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Add Praktika role group
  addPraktikaGroup = async groupData => {
    const addGroupResponse = await addPraktikaGroup(groupData);
    try {
      runInAction(() => {
        this.addGroupResponse = addGroupResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.addGroupResponse = error;
      });
    }
    return this.addGroupResponse;
  };

  // export group contacts
  getExportGroupContacts = async (id, values) => {
    const response = await getExportGroupContacts(id, values);
    try {
      runInAction(() => {
        this.exportResponse = response;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
        this.exportResponse = error;
      });
    } finally {
      return this.exportResponse;
    }
  };
}

decorate(ObservableGroupStore, {
  group: observable,
  quickGroup: observable,
  groupList: observable,
  groupData: observable,
  selectedGroup: observable,
  addGroupResponse: observable,
  updateGroupResponse: observable,
  deleteGroupResponse: observable,
  selectedGroupFields: observable,
  initialSingleContactValues: observable,
  blocked: observable,
  pageSize: observable,
  current: observable,
  search: observable,
  totalPages: observable,
  total: observable,
  isMobile: observable,
  sortField: observable,
  sortOrder: observable,

  groupDataContactList: observable,
  groupDataSearch: observable,
  groupDataCurrent: observable,
  groupDataTotal: observable,
  groupDataTotalPages: observable,
  groupDataPageSize: observable,
  groupDatasortField: observable,
  groupDatasortOrder: observable,

  contact: observable,
  exportResponse: observable,

  selectedContacts: observable,
  selectedGroupIds: observable,
  selectedGroupObj: observable
});
export default new ObservableGroupStore();
