import React, { useState, useRef, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Row, Col, Tooltip, Form, Typography } from "antd";
import * as Yup from "yup";
import { Formik } from "formik";
import AppTable from "components/AppTable/AppTable";
import AppButton from "components/AppButton/AppButton";
import FormSelectInput from "components/FormItems/FormSelectInput";
import SelectMergeLabels from "../Modals/SelectMergeLabels";
import AlertModal from "components/Modals/AlertModal";
import FormInput from "components/FormItems/FormInput";
import { checkNumberwithRegex } from "constant/contacts";

const validationSchema = Yup.object().shape({
  mergeLabels: Yup.array()
    .of(
      Yup.object().shape({
        newLabel: Yup.string(),
        oldLabel: Yup.string()
      })
    )
    .max(10, "You can only select 10 labels")
    .required("Required")
});

const tableData = [
  {
    id: 0,
    newLabel: "mobile number",
    oldLabel: ""
  },
  {
    id: 1,
    newLabel: "first name",
    oldLabel: ""
  },
  {
    id: 2,
    newLabel: "last name",
    oldLabel: ""
  }
];

const MergeLabelsForm = ({ setAlert, connectApp = false, onSuccess = () => {}, footerProps, modalStore, groupStore, authStore, setGroupId, setSuccessAlert, defaultLabel }) => {
  const { group, quickGroup, groupData, addGroup, addContacts, getGroupById, getAllGroupDataList, addPraktikaGroup } = groupStore;

  const formikRef = useRef();
  const [value, setValue] = useState(undefined);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [numberLabelError, setNumberLabelError] = useState(false);
  const [editLabel, setEditLabel] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [editValue, setEditValue] = useState("");
  const [data, setData] = useState(defaultLabel ? [...tableData, ...defaultLabel] : tableData);
  const [misMatchedLabels, setMisMatchedLabels] = useState(false);
  const [validNumber, setValidNumber] = useState("N/A");
  const [mergeLabels, setMergeLabels] = useState(
    groupData?.headers
      ? connectApp
        ? groupData?.headers.map((item, i) => ({
            label: item.newLabel,
            value: `${item.newLabel}$-${i}`,
            // value: item.oldLabel,
            type: item.type
          }))
        : groupData?.headers.map(item => {
            return {
              label: item,
              value: item
            };
          })
      : []
  );
  const sortAndFilter = (arr = [], field) => {
    return arr
      .filter(item => (field ? item.type === field : true) && !item?.hide)
      .sort((a, b) => {
        try {
          `${a?.label}`?.localeCompare(`${b.label}`);
        } catch (er) {
          setAlert(true);
          console.log(er);
          return 0;
        }
      });
  };

  const formatText = text => {
    try {
      if (typeof text !== "string") {
        setAlert(true);
        return "";
      }
      return typeof text === "string" ? text.replace(/\$.*/, "") : text;
    } catch (e) {
      console.log(e);
      setAlert(true);
      return text;
    }
  };

  useEffect(() => {
    if (group && group.fields) {
      setData([
        {
          id: 0,
          newLabel: "mobile number",
          oldLabel: ""
        },
        ...group.fields.map((item, i) => {
          return {
            id: i + 1,
            newLabel: item,
            oldLabel: ""
          };
        })
      ]);
    }
  }, [group]);

  const columnsOnly = [
    {
      title: "Merge Labels",
      dataIndex: "newLabel",
      className: "fw-bold text-capitalize",
      ellipsis: true,
      render: (text, record, index) => {
        if (text === "mobile number") {
          return (
            <Row type="flex" justify="space-between">
              <Col span={22} className="text-truncate">
                {formatText(text)}
              </Col>
              <Col span={2}>
                <Tooltip title="Select Number Field Only">
                  <i className="fas fa-info-circle text-info-color fs-6" />
                </Tooltip>
              </Col>
            </Row>
          );
        } else {
          return (
            <div className="d-flex justify-content-between gap-2 align-items-center">
              {editLabel && editIndex == index ? (
                <>
                  <FormInput
                    containerClassname="m-0"
                    name="newLabel"
                    placeholder="Enter Label"
                    value={editValue}
                    onChange={e => setEditValue(e.target.value)}
                    inputProps={{
                      maxLength: 80,
                      onPressEnter: () => {
                        const newData = [...data];
                        newData[index].newLabel = editValue;
                        setData(newData);
                        setEditIndex(null);
                      }
                    }}
                    size="default"
                  />
                  <Tooltip title="Save Label">
                    <i
                      className="fas fa-save text-info-color fs-6"
                      onClick={() => {
                        const newData = [...data];
                        newData[index].newLabel = editValue;
                        setData(newData);
                        setEditIndex(null);
                      }}
                      type="button"
                    />
                  </Tooltip>
                </>
              ) : (
                <>
                  <Col span={22} className="text-truncate me-auto">
                    {formatText(text)}
                  </Col>
                  {/* <Tooltip title="Edit Label">
                    <i
                      className="fas fa-pen text-info-color fs-6"
                      onClick={() => handleEditLabel(text)}
                      type="button"
                    />
                  </Tooltip> */}
                </>
              )}
              <Col span={2}>
                <Tooltip title="Delete Label">
                  <i className="fas fa-trash-alt text-danger-color fs-6" onClick={() => handleRemoveLabel(record)} type="button" />
                </Tooltip>
              </Col>
            </div>
          );
        }
      }
    },
    {
      title: "Assign To",
      dataIndex: "oldLabel",
      ellipsis: true,
      render: (text, record, index) => {
        if (!text) {
          return (
            <FormSelectInput
              showSearch
              name="mergeLabels"
              value={value}
              onChange={e => {
                setData(
                  data.map(item => {
                    if (item.newLabel === record.newLabel) {
                      return {
                        ...item,
                        oldLabel: e
                      };
                    }
                    return item;
                  })
                );
                setMergeLabels(mergeLabels.map(item => (item.value === e ? { ...item, hide: true } : item)));
                setValue(undefined);
              }}
              options={index === 0 && connectApp ? sortAndFilter(mergeLabels, "phonenumber") : sortAndFilter(mergeLabels)}
              size="default"
            />
          );
        } else {
          return (
            <Row type="flex" justify="space-between">
              <Col span={22} className="text-truncate">
                {formatText(text)}
              </Col>
              <Col span={2}>
                <Tooltip title="Change Label">
                  <i className="fas fa-edit text-info-color fs-6" onClick={() => handleChangeLabel(text)} type="button" />
                </Tooltip>
              </Col>
            </Row>
          );
        }
      }
    },
    !connectApp && {
      title: "Sample Data",
      dataIndex: "sampleData",
      ellipsis: true,
      render: (text, record) => {
        if (groupData?.xlsFileData && groupData?.xlsFileData.length === 0) {
          setMisMatchedLabels(true);
          return "N/A";
        }
        if (record.oldLabel && groupData?.xlsFileData.length > 0) {
          if (record.newLabel === "mobile number") {
            checkNumberRow(record);
            return validNumber;
          } else {
            return groupData?.xlsFileData[0][record.oldLabel];
          }
        }
      }
    }
  ].filter(Boolean);

  async function checkNumberRow(record) {
    let numberRows = groupData?.xlsFileData.map(item => item[record.oldLabel]);
    await checkNumberwithRegex(numberRows).then(res => {
      if (!res.status) {
        setMisMatchedLabels(true);
        setValidNumber("N/A");
      } else {
        setMisMatchedLabels(false);
        setValidNumber(res.validNumber[0]);
      }
    });
  }

  function handleEditLabel(text) {
    setEditLabel(true);
    setEditValue(text);
    setEditIndex(data.findIndex(item => item.newLabel === text));
  }

  function handleRemoveLabel(record) {
    setData(data.filter(item => item.newLabel !== record.newLabel));
    setSelectedLabels([]);
    if (record.oldLabel) {
      setMergeLabels([...mergeLabels, { label: record.oldLabel, value: record.oldLabel }]);
    }
  }

  function handleChangeLabel(text) {
    setData(
      data.map(item => {
        if (item.oldLabel === text) {
          return {
            ...item,
            oldLabel: ""
          };
        }
        return item;
      })
    );
    // setMergeLabels(sortAndFilter([...mergeLabels, { label: text, value: text }]));
    setMergeLabels(mergeLabels.map(item => (item.value === text ? { ...item, hide: false } : item)));
    setValue(undefined);
  }

  function AddLabel(e) {
    e.preventDefault();
    if (data[0] && data[0].oldLabel === "") {
      setNumberLabelError(true);
    } else {
      modalStore.toggleModal("showAddMergeLabelModal", true);
    }
  }

  const initialValues = {
    mergeLabels: data
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        innerRef={formikRef}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (values.mergeLabels[0].oldLabel === "") {
            setNumberLabelError(true);
            setSubmitting(false);
            return false;
          }
          values.mergeLabels = values.mergeLabels.filter(item => item.oldLabel !== "");

          if (connectApp) {
            const reset = () => {
              resetForm();
              setSubmitting(false);
            };

            // finding selected labels in whole array then returns modified oldLabel
            const finalArr = values.mergeLabels.map(item => ({
              newLabel: formatText(item.newLabel),
              oldLabel: groupData.headers.find(data => data.newLabel === formatText(item.oldLabel))?.oldLabel || formatText(item.oldLabel)
            }));
            onSuccess({ ...groupData, ...{ mergeLabels: finalArr } }, reset);
          } else {
            values.mergeLabels = JSON.stringify(values.mergeLabels);
            values = {
              ...groupData,
              ...values
            };

            let formData = new FormData();
            Object.entries(values).forEach(([key, value]) => {
              if (!["xlsFileData", "headers"].includes(key)) formData.append(key, value);
            });

            if (group && !quickGroup) {
              addContacts(formData, group._id)
                .then(res => {
                  if (res.status) {
                    setSuccessAlert(true);
                    getGroupById(group._id);
                    getAllGroupDataList(group._id);
                  }
                })
                .finally(() => {
                  modalStore.toggleModal("showMergeLabelModal", false);
                  setData(tableData);
                  resetForm();
                  setSubmitting(false);
                });
            } else {
              if (authStore.user?.profile === "praktika") {
                addPraktikaGroup(formData)
                  .then(res => {
                    if (res.status) {
                      setSuccessAlert(true);
                      setGroupId(res.data.group._id);
                    }
                  })
                  .finally(() => {
                    modalStore.toggleModal("showMergeLabelModal", false);
                    resetForm();
                    setData(tableData);
                    setSubmitting(false);
                  });
                return;
              }

              addGroup(formData)
                .then(res => {
                  if (res.status) {
                    setSuccessAlert(true);
                    setGroupId(res.data.group._id);
                  }
                })
                .finally(() => {
                  modalStore.toggleModal("showMergeLabelModal", false);
                  resetForm();
                  setData(tableData);
                  setSubmitting(false);
                });
            }
          }
        }}
      >
        {({ errors, touched, handleSubmit, isSubmitting, setFieldValue }) => (
          <Form onSubmit={handleSubmit} className="h-100">
            <Row gutter={[10, 10]} type="flex" justify="center" className="h-100">
              <Col span={24}>
                <AppTable dataSource={data} columns={columnsOnly} rowKey={record => record.id} pagination={false} />
              </Col>
              {errors.mergeLabels && touched.mergeLabels && (
                <Col span={20}>
                  <Typography.Text className="text-danger-color">{errors.mergeLabels}</Typography.Text>
                </Col>
              )}
              {misMatchedLabels && (
                <Col span={20}>
                  <Typography.Text className="text-danger-color">Label (row) assign to mobile number do not contains valid mobile number or file you selected is incorrect</Typography.Text>
                </Col>
              )}
              {footerProps && footerProps}
              {!group && (
                <Col span={footerProps ? "auto" : 10} className="text-end align-self-end">
                  <AppButton label={mergeLabels.length === 0 ? "All Label Selected" : "Add Label"} onClick={AddLabel} light disabled={mergeLabels.length === 0} withoutBg />
                </Col>
              )}
              <Col span={footerProps ? "auto" : group ? 20 : 10} className={`align-self-end text-${group ? "center" : "start"}`}>
                <AppButton
                  label="Finalize Import"
                  type="submit"
                  light
                  isSubmitting={isSubmitting}
                  postfixIcon={<i className="bx bx-right-arrow-alt ms-1" />}
                  disabled={isSubmitting || misMatchedLabels}
                  onClick={() => setFieldValue("mergeLabels", data)}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      {modalStore.showAddMergeLabelModal && (
        <SelectMergeLabels
          selectedLabels={selectedLabels}
          setSelectedLabels={setSelectedLabels}
          mergeLabels={sortAndFilter(mergeLabels)}
          setMergeLabels={setMergeLabels}
          setData={setData}
          data={data}
          formatText={formatText}
          setAlert={setAlert}
        />
      )}
      <AlertModal
        title="Select Number Field"
        content="Please select number field first"
        visible={numberLabelError}
        error
        primaryBtnProps={{
          label: "Ok",
          onClick: () => setNumberLabelError(false)
        }}
        closable
        onCancel={() => setNumberLabelError(false)}
      />
    </>
  );
};

export default inject(stores => ({
  modalStore: stores.store.modalStore,
  groupStore: stores.store.groupStore,
  authStore: stores.store.authStore
}))(observer(MergeLabelsForm));
