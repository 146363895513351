import { Col, Row } from "antd";
import AppButton from "components/AppButton/AppButton";
import FormInput from "components/FormItems/FormInput";
import { Form, Formik } from "formik";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import ActiveCampLogo from "assets/images/thirdparty app/activeCampaignLogo.png";
import MergeLabelsForm from "components/TargetGroup/GroupForms/MergeLabelsForm";
import GroupForm from "./Forms/GroupForm";
import SpinnerLoader from "components/AppLoader/SpinnerLoader";
import { isEmpty } from "lodash";
import { activeCampUrlRegExp } from "constant/messageConstants";

const ActiveCampaignApp = ({ groupStore, integration, setStep, authStore, onSuccess }) => {
  const { activeCampFetchFields, activeCampFetchFilter, handleConnect, importContacts, groupName } = integration;
  const { setGroupData, group } = groupStore;
  const { user } = authStore;

  const [filters, setFilters] = useState();
  const [loading, setLoading] = useState(false);
  const [domainStep, setDomainStep] = useState(isEmpty(user?.activeCampaignToken) ? 1 : 2);

  useEffect(() => {
    if (domainStep === 2) {
      setLoading(true);
      activeCampFetchFilter().finally(() => setLoading(false));
    }
    if (domainStep === 3) {
      setLoading(true);
      activeCampFetchFields()
        .then(res => {
          if (res.length > 0)
            setGroupData({
              headers: res.map((_, i) => ({
                id: `ac-${i}`,
                newLabel: _?.label,
                oldLabel: _?.name,
                type: _?.fieldType
              }))
            });
        })
        .catch(err => {
          console.log(err);
          setDomainStep(2);
        })
        .finally(() => setLoading(false));
    }
  }, [domainStep]);

  const onMergeLabels = (values, reset) => {
    values.name = groupName;
    delete values.headers;

    if (group) {
      values.name = group?.name;
      values.groupid = group?._id;
    }

    const data = filters?.filter === "all" ? { filter: "all", ...values } : { ...filters, ...values };

    importContacts("ac", data)
      .then(res => {
        if (res?.status) {
          onSuccess(res.data);
        }
      })
      .finally(() => {
        reset();
      });
  };

  const onBack = () => {
    user?.activeCampaignToken ? setStep(1) : setDomainStep(1);
  };

  return (
    <Row gutter={20} type="flex" justify="center" align="middle" className="h-100">
      {domainStep === 1 ? (
        <Col md={20} lg={18} xl={16}>
          <Formik
            enableReinitialize
            initialValues={{
              accountName: "",
              activeCampaignToken: ""
            }}
            validationSchema={Yup.object().shape({
              accountName: Yup.string().when([], {
                is: () => domainStep,
                then: Yup.string()
                  .matches(activeCampUrlRegExp, "Enter valid Active Campaign url")
                  .required("Required")
              }),
              activeCampaignToken: Yup.string().when([], {
                is: () => domainStep,
                then: Yup.string().required("Required")
              })
            })}
            onSubmit={(values, { setSubmitting }) => {
              const data = { ...values };
              data.accountName = values?.accountName?.match(activeCampUrlRegExp)?.[1];
              handleConnect(
                "ac",
                () => {
                  setDomainStep(2);
                },
                data
              );
              setSubmitting(false);
            }}
          >
            {({ isSubmitting, errors, touched, values, handleChange }) => {
              return (
                <Form className="">
                  <Row type="flex" justify="center" gutter={[0, 16]}>
                    <Col span={24} className="text-center mb-3">
                      <img src={ActiveCampLogo} width="95" alt="active campaign logo" />
                      <p className="text-muted text-dark fs-5 mt-3">Enter your Active Campaign's Account detail to sync contacts.</p>
                    </Col>
                    <Col span={24}>
                      <FormInput
                        name={"accountName"}
                        value={values.accountName}
                        onChange={handleChange}
                        size="default"
                        placeholder="Account url e.g. https://guni.activehosted.com"
                        error={errors.accountName}
                        touched={touched.accountName}
                        containerClassname="mb-0"
                      />
                    </Col>
                    <Col span={24}>
                      <FormInput
                        name={"activeCampaignToken"}
                        value={values.activeCampaignToken}
                        onChange={handleChange}
                        size="default"
                        placeholder="Account token"
                        error={errors.activeCampaignToken}
                        touched={touched.activeCampaignToken}
                        containerClassname="mb-0"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} className="mt-5 vh-center gap-3">
                      <AppButton label={"Previous"} light withoutBg prefixIcon={<i className="bx bx-left-arrow-alt me-1" />} onClick={() => setStep(1)} />
                      <AppButton label="Connect" type="submit" postfixIcon={<i className="bx bx-right-arrow-alt ms-1" />} light disabled={isSubmitting} />
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Col>
      ) : domainStep === 2 ? (
        <Col span={24}>
          <GroupForm
            app={"ac"}
            onBack={onBack}
            setStep={setDomainStep}
            onSuccess={_ =>
              setFilters({
                filter: _?.filter,
                listid: _?.listid,
                status: _?.status,
                tagid: _?.tagid
              })
            }
          />
        </Col>
      ) : (
        <Col span={24}>
          {loading ? (
            <div className="text-center m-5 vh-center gap-3 fw-bold pt-5">
              <SpinnerLoader />
              Fetching Active Camapaign fields...
            </div>
          ) : (
            <MergeLabelsForm
              connectApp
              onSuccess={onMergeLabels}
              defaultLabel={[
                {
                  id: 3,
                  newLabel: "Email",
                  oldLabel: ""
                }
              ]}
              footerProps={
                <Col className="align-self-end">
                  <AppButton label={"Previous"} light withoutBg prefixIcon={<i className="bx bx-left-arrow-alt me-1" />} onClick={() => setDomainStep(2)} />
                </Col>
              }
            />
          )}
        </Col>
      )}
    </Row>
  );
};

export default inject(stores => ({
  authStore: stores.store.authStore,
  groupStore: stores.store.groupStore,
  integration: stores.store.integrationStore
}))(observer(ActiveCampaignApp));
