import { runInAction, decorate, observable } from "mobx";
import {
  getAllUsers,
  addUser,
  deleteUser,
  updateUser,
  getAllPlans,
  subscribeToPlan,
  getAppToken,
  createAppToken,
  deleteAppToken,
  addCredit,
  addWebHook,
  getUsage,
  getAllGatewayPlans,
  getGatewayUsage,
  getMmsGatewayUsage,
  updateGatewayCredit,
  updateProfileConfig,
  verifyCoupon,
  addSpecialPlan,
  getSpecialPlansByUser,
  getMySpecialPlans,
  deleteSpecialPlan,
  fetchCurrencies,
  addPurchaseOrder,
  getUserAllPurchaseOrders,
  deletePurchaseOrder,
  updatePurchaseOrder,
  getCards,
  getDashboardStepsData,
  customPayment,
  getUserLoginInformation,
  googleSignup,
  videoCompression,
  deleteCard,
  buyProductBundle,
  revealTokenAuth,
  revealToken
} from "service/userService";
import { getUsersTokens } from "service/adminService";

class ObservableUserStore {
  user = undefined;
  userList = [];
  products = [];
  usage = [];
  gatewayUsage = [];
  appTokens = [];
  newToken = false;
  webHooks = [];
  addUserResponse = undefined;
  updateUserResponse = undefined;
  selectedUser = undefined;
  deleteUserResponse = undefined;
  pageSize = 10;
  current = 1;
  search = "";
  sortField = "createdAt";
  sortOrder = "descend";
  specialPlan = undefined;
  dashboardStepsData = {};
  loginInformation = [];
  compressedItem = [];
  buyCreditPackage = undefined;
  response = undefined;
  usersTokens = [];

  changeSorting = fieldObj => {
    runInAction(() => {
      const { field, sortOrder } = fieldObj;
      this.sortField = field;
      this.sortOrder = sortOrder;
    });
  };

  setSearch = search => {
    runInAction(() => {
      this.search = search;
    });
  };

  setPage = current => {
    runInAction(() => {
      this.current = current || 1;
    });
  };

  setPageSize = pageSize => {
    runInAction(() => {
      this.pageSize = pageSize;
    });
  };

  verifyCoupon = async params => {
    const verifyCouponResponse = await verifyCoupon(params);
    return verifyCouponResponse;
  };

  /**
   * get all Users
   * @returns {Promise<[]>}
   */
  getAllUserList = async () => {
    // call & save User list response
    let params = {
      page: this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      pageSize: this.pageSize
    };
    if (this.search) params.search = this.search;
    const userListResponse = await getAllUsers(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize } = userListResponse;
        this.userList = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
        this.selectedUser = undefined;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }

    return this.userList;
  };

  getUserLoginInformation = async userId => {
    const items = await getUserLoginInformation(userId);
    try {
      runInAction(() => {
        this.loginInformation = items.data.information;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
    return this.loginInformation;
  };

  /**
   * add new User
   * @param userData
   * @returns {Promise<*>}
   */
  addUser = async userData => {
    // call api for add new User
    const addUserResponse = await addUser(userData);
    try {
      runInAction(() => {
        this.addUserResponse = addUserResponse;
      });
      this.getAllUserList();
    } catch (error) {
      runInAction(() => {
        this.addUserResponse = error;
      });
    }
    return this.addUserResponse;
  };

  /**
   * select User Data for Update
   * @param userData
   */
  selectUser = userData => {
    this.selectedUser = userData
      ? JSON.parse(JSON.stringify(userData))
      : undefined;
  };

  /**
   * updates User Data
   * @param userData
   * @param userId
   * @returns {Promise<void>}
   */
  updateUser = async userData => {
    // call api for update existing User
    const updateUserResponse = await updateUser(userData, userData._id);
    try {
      runInAction(() => {
        this.updateUserResponse = updateUserResponse;
        this.selectedUser = undefined;
      });
      this.getAllUserList();
    } catch (error) {
      runInAction(() => {
        this.updateUserResponse = error;
      });
    }
    return this.updateUserResponse;
  };

  /**
   * DeActive User
   * @param userId
   * @returns {Promise<void>}
   */
  deActiveUser = async userId => {
    const deleteUserResponse = await deleteUser(userId);
    try {
      runInAction(() => {
        this.deleteUserResponse = deleteUserResponse;
        this.userList = this.userList.map(user => {
          if (user._id === deleteUserResponse._id) {
            return deleteUserResponse;
          }
          return user;
        });
      });
    } catch (error) {
      runInAction(() => {
        this.deleteUserResponse = error;
      });
    }
    return this.updateUserResponse;
  };

  addCreditToUser = async (userId, credit) => {
    const userCreditResponse = await addCredit(userId, credit);
    try {
      runInAction(() => {
        this.updateUserResponse = userCreditResponse;
        this.userList = this.userList.map(user => {
          if (user._id === userCreditResponse._id) {
            return userCreditResponse;
          }
          return user;
        });
      });
    } catch (error) {
      runInAction(() => {
        this.updateUserResponse = error;
      });
    }
    return this.updateUserResponse;
  };

  getAllPlans = async () => {
    const productsResponse = await getAllPlans();
    try {
      runInAction(() => {
        this.products = productsResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.products = productsResponse;
      });
    }
    return this.products;
  };

  subscribeToPlan = async params => {
    const userResponse = await subscribeToPlan(params);
    try {
      runInAction(() => {
        this.user = userResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.user = userResponse;
      });
    }
    return this.user;
  };

  customPayment = async params => {
    const userResponse = await customPayment(params);
    try {
      runInAction(() => {
        this.user = userResponse.data;
        this.paymentStatus = userResponse.data;
      });
    } catch (error) {
      runInAction(() => {
        this.user = userResponse.data;
        this.paymentStatus = userResponse.data;
      });
    }

    return this.paymentStatus;
  };

  createAppToken = async data => {
    let response = await createAppToken(data);
    response.data.newToken = true;

    try {
      runInAction(() => {
        if (response.status) {
          this.newToken = true;
          this.appTokens = this.appTokens
            .map(token => {
              if (token.newToken) {
                return {
                  ...token,
                  newToken: false,
                  token: undefined
                };
              } else {
                return token;
              }
            })
            .concat(response.data);
        }
      });
    } catch (error) {
      this.newToken = false;
    }
    return this.newToken;
  };

  getAppToken = async () => {
    const response = await getAppToken();
    try {
      runInAction(() => {
        this.newToken = false;
        this.appTokens = response.data;
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.appTokens;
  };

  deleteAppToken = async tokenId => {
    const response = await deleteAppToken(tokenId);
    try {
      runInAction(() => {
        if (response.status) {
          this.appTokens = this.appTokens.filter(
            token => token._id !== tokenId
          );
          if (!this.appTokens.find(token => token.newToken))
            this.newToken = false;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.appTokens;
  };

  addWebHook = async body => {
    const webHooks = await addWebHook(body);
    try {
      runInAction(() => {
        this.webHooks = webHooks;
      });
    } catch (error) {}
    return this.webHooks;
  };

  getUsage = async () => {
    const usageResponse = await getUsage();
    try {
      runInAction(() => {
        this.usage = usageResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.usage = usageResponse;
      });
    }
    return this.usage;
  };

  //For GATEWAY ---> Filtering plans

  getAllGatewayPlans = async () => {
    const productsResponse = await getAllGatewayPlans();
    try {
      runInAction(() => {
        this.products = productsResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.products = productsResponse;
      });
    }
    return this.products;
  };

  getGatewayUsage = async () => {
    const gatewayUsageResponse = await getGatewayUsage();
    try {
      runInAction(() => {
        if (gatewayUsageResponse && gatewayUsageResponse[0]._id)
          gatewayUsageResponse[0]._id = new Date(
            gatewayUsageResponse[0]._id
          ).toLocaleDateString();
        this.gatewayUsage = gatewayUsageResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.gatewayUsage = gatewayUsageResponse;
      });
    }
    return this.gatewayUsage;
  };

  getMmsGatewayUsage = async () => {
    const gatewayUsageResponse = await getMmsGatewayUsage();
    try {
      runInAction(() => {
        if (gatewayUsageResponse && gatewayUsageResponse[0]._id)
          gatewayUsageResponse[0]._id = new Date(
            gatewayUsageResponse[0]._id
          ).toLocaleDateString();
        this.gatewayUsage = gatewayUsageResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.gatewayUsage = gatewayUsageResponse;
      });
    }
    return this.gatewayUsage;
  };

  updateGatewayCreditForUser = async (userId, params) => {
    const userGatewayCreditResponse = await updateGatewayCredit(userId, params);
    try {
      runInAction(() => {
        this.updateUserResponse = userGatewayCreditResponse;
        this.userList = this.userList.map(user => {
          if (user._id === userGatewayCreditResponse._id) {
            return userGatewayCreditResponse;
          }
          return user;
        });
      });
    } catch (error) {
      runInAction(() => {
        this.updateUserResponse = error;
      });
    }
    return this.updateUserResponse;
  };

  addSpecialPlanToUser = async ({ user, centsPerCredit }) => {
    const response = await addSpecialPlan({
      user,
      centsPerCredit
    });

    return response;
  };

  fetchSpecialPlansByUser = async ({ userId }) => {
    const response = await getSpecialPlansByUser({
      userId
    });
    return response;
  };

  deleteUserSpecialPlan = async ({ userId, planId }) => {
    const response = await deleteSpecialPlan({ userId, planId });
    return response;
  };

  fetchMySpecialPlans = async () => {
    const response = await getMySpecialPlans();
    try {
      runInAction(() => {
        this.specialPlan = response.data.plan;
      });
    } catch (error) {
      runInAction(() => {
        this.specialPlan = response.data.plan;
      });
    }
    return response;
  };

  fetchCurrencies = async () => {
    const response = await fetchCurrencies();
    return response;
  };

  addPurchaseOrder = async data => {
    const {
      user,
      totalAmount,
      currency,
      isSMSAllow,
      isMMSAllow,
      centsPerSMS,
      centsPerMMS
    } = data;
    const response = await addPurchaseOrder({
      user,
      totalAmount,
      currency,
      isSMSAllow,
      isMMSAllow,
      centsPerSMS,
      centsPerMMS
    });

    return response;
  };

  getUserAllPurchaseOrders = async data => {
    const { user } = data;
    const response = await getUserAllPurchaseOrders({ user });

    return response;
  };

  deletePurchaseOrder = async data => {
    const { userToSearch, orderId } = data;
    const response = await deletePurchaseOrder({
      userToSearch,
      orderId
    });

    return response;
  };

  updatePurchaseOrder = async data => {
    const response = await updatePurchaseOrder(data);

    return response;
  };

  getCards = async () => {
    const response = await getCards();
    return response;
  };

  getDashboardStepsData = async () => {
    const response = await getDashboardStepsData();
    try {
      runInAction(() => {
        this.dashboardStepsData = response;
      });
    } catch (error) {
      console.log(error);
    }
    return this.dashboardStepsData;
  };

  compressVideo = async data => {
    const response = await videoCompression(data);
    try {
      runInAction(() => {
        this.compressedItem = response;
      });
    } catch (error) {
      console.log(error);
    }
    return this.compressedItem;
  };

  deleteCard = async cardId => {
    const response = await deleteCard(cardId);
    return response;
  };

  setCreditPackage = async data => {
    runInAction(() => {
      this.buyCreditPackage = data
        ? JSON.parse(JSON.stringify(data))
        : undefined;
    });
  };

  buyProductBundle = async data => {
    const response = await buyProductBundle(data);
    return response;
  };

  getUsersTokens = async id => {
    let param = {
      userId: id
    };
    const response = await getUsersTokens(param);
    try {
      runInAction(() => {
        this.usersTokens = response;
      });
    } catch (error) {
      runInAction(() => {
        this.usersTokens = error;
      });
    }
    return this.usersTokens;
  };

  revealTokenAuth = async id => {
    const response = await revealTokenAuth(id);
    try {
      runInAction(() => {
        this.response = response;
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  revealToken = async data => {
    let params = {
      tokenId: data.tokenId,
      otp: data.otp
    };
    const response = await revealToken(params);
    try {
      runInAction(() => {
        this.response = response;
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };
}

decorate(ObservableUserStore, {
  user: observable,
  appTokens: observable,
  newToken: observable,
  webHooks: observable,
  userList: observable,
  products: observable,
  usage: observable,
  addUserResponse: observable,
  updateUserResponse: observable,
  selectedUser: observable,
  deleteUserResponse: observable,
  pageSize: observable,
  current: observable,
  total: observable,
  sortField: observable,
  sortOrder: observable,
  search: observable,
  gatewayUsage: observable,
  specialPlan: observable,
  dashboardStepsData: observable,
  loginInformation: observable,
  compressedItem: observable,
  response: observable,
  usersTokens: observable
});
export default new ObservableUserStore();
