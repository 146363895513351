import React, { useState, useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Typography, Row, Col } from "antd";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import queryString from "query-string";
import FormInput from "components/FormItems/FormInput";
import AppButton from "components/AppButton/AppButton";
import AlertModal from "components/Modals/AlertModal";
import MessageType from "components/GatewayMessage/MessageType";
import MessageContainer from "components/GatewayMessage/MessageContainer";
import { sharedNumber } from "constant/constant";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Name must be more then 2 characters long")
    .max(80, "Can not be longer then 80 characters")
    .required("Enter a name for the Template"),
  content: Yup.string()
    .min(2, "Template Content must be more then 2 characters long")
    .required("Enter Template Content")
});

const TemplateForm = props => {
  const {
    templateStore,
    addTempBtn = true,
    modalStore,
    authStore,
    isMms
  } = props;

  const {
    selectedTemplate,
    addTemplate,
    addMmsTemplate,
    updateTemplate,
    updateMmsTemplate,
    getTemplateById,
    selectTemplate,
    getAllTemplateList,
    addGlobalTemplate,
    setVisibility
  } = templateStore;
  const { user, dedicatedNumList } = authStore;

  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const formikRef = useRef();
  const [successAlert, setSuccessAlert] = useState(false);
  const [saveFormat, setSaveFormat] = useState(false);

  useEffect(() => {
    if (
      user?.role == "subaccount" &&
      user?.access?.find(x => x === "mms") ^
        user?.access?.find(x => x === "sms")
    ) {
      history.push("/");
    }
  }, [user]);

  useEffect(() => {
    if (id && !selectedTemplate && location.pathname.includes("template")) {
      getTemplateById(id);
    }
  }, [id]);

  useEffect(() => {
    if (location.pathname.includes("save")) {
      setSaveFormat(true);
    } else {
      setSaveFormat(false);
    }
  }, []);

  const qryStr = queryString.parse(location.search);

  useEffect(() => {
    if (qryStr.global) modalStore.setIsMms(false);
  }, [qryStr.global]);

  const initialValues = {
    name: selectedTemplate
      ? selectedTemplate.name + (saveFormat ? " Copy" : "")
      : "",
    content: selectedTemplate ? selectedTemplate.content : "",
    template_type: "normal",
    sender:
      !isMms &&
      [
        ...dedicatedNumList[isMms ? "mms" : "sms"],
        ...dedicatedNumList?.senders,
        ...dedicatedNumList?.mobile
      ].includes(user?.defaultSender)
        ? user?.defaultSender
        : dedicatedNumList[isMms ? "mms" : "sms"].includes(user?.defaultSender)
        ? user?.defaultSender
        : sharedNumber,
    url:
      selectedTemplate && selectedTemplate.media && isMms
        ? selectedTemplate.media
        : undefined,
    subject:
      selectedTemplate && selectedTemplate.subject && isMms
        ? selectedTemplate.subject
        : undefined,
    mediaName:
      selectedTemplate && selectedTemplate.media && isMms
        ? "File Attached"
        : undefined
  };

  const handleSuccessAlert = () => {
    if (addTempBtn) {
      history.push("/template");
      modalStore.toggleModal("showCreateTemplateModal", false);
    } else {
      modalStore.toggleModal("showCreateTemplateModal", false);
    }
    selectTemplate(null);
    setSuccessAlert(false);
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          values.sender = undefined;
          values.template_type = undefined;
          let media = values.media ? true : false;
          let url = values.url ? true : false;
          if (isMms && !(media ^ url)) {
            modalStore.toggleModal("showAttachFileModal", true);
            setSubmitting(false);
            return;
          }
          let formData = new FormData();
          formData.append("bucket", "general");
          Object.entries(values).forEach(([key, value]) => {
            if (value) {
              if (
                !["template_type", "mediaName", "mediaType", "sender"].includes(
                  key
                )
              )
                formData.append(key, value);
            }
          });
          if (values.media) formData.delete("url");
          if (selectedTemplate) {
            if (isMms) {
              await updateMmsTemplate(formData, selectedTemplate._id)
                .then(res => {
                  if (res.status) {
                    setSuccessAlert(true);
                    if (saveFormat) setVisibility("user");
                  }
                })
                .finally(() => setSubmitting(false));
            } else {
              await updateTemplate(values, selectedTemplate._id)
                .then(res => {
                  if (res.status) {
                    setSuccessAlert(true);
                    if (saveFormat) setVisibility("user");
                  }
                })
                .finally(() => setSubmitting(false));
            }
          } else {
            if (qryStr.global) {
              await addGlobalTemplate(values)
                .then(res => {
                  if (res.status) setSuccessAlert(true);
                })
                .finally(() => setSubmitting(false));
            } else {
              if (isMms) {
                await addMmsTemplate(formData)
                  .then(res => {
                    if (res.status) setSuccessAlert(true);
                  })
                  .finally(() => setSubmitting(false));
              } else {
                await addTemplate(values)
                  .then(res => {
                    if (res.status) setSuccessAlert(true);
                  })
                  .finally(() => setSubmitting(false));
              }
            }
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          isSubmitting,
          setFieldValue
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row gutter={[10, 20]}>
              {!qryStr.global && !id && addTempBtn && (
                <Col span={24}>
                  <MessageType />
                </Col>
              )}
              <Col span={24}>
                <Typography.Title level={4} className="fs-6">
                  Template Name
                </Typography.Title>
              </Col>
              <Col span={24}>
                <FormInput
                  name="name"
                  placeholder="Enter Template Name"
                  containerClassname="mb-0"
                  value={values.name}
                  size="default"
                  error={errors.name}
                  touched={touched.name}
                  onChange={handleChange}
                />
              </Col>
              <MessageContainer
                size="default"
                formikRef={formikRef}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                errors={errors}
                shortUrl={false}
                optoutCheckBox={false}
                touched={touched}
                values={values}
                name={"content"}
                addTemplateBtn={addTempBtn}
                testSMS={addTempBtn}
                isMms={isMms}
                quickMessage
                smallSize
                zIndex={1033}
                minRows={3}
                scheduler={false}
                fileAttachBtn={isMms}
                endProp={
                  <>
                    <AppButton
                      light
                      label={
                        selectedTemplate
                          ? isSubmitting
                            ? saveFormat
                              ? "Saving..."
                              : "Updating..."
                            : saveFormat
                            ? "Save As Copy"
                            : "Update Template"
                          : isSubmitting
                          ? "Adding..."
                          : "Add Template"
                      }
                      type="submit"
                      size="default"
                      className="rounded-xl"
                      disabled={isSubmitting}
                    />
                    <AppButton
                      light
                      withoutBg
                      label="Cancel"
                      type="button"
                      size="default"
                      className="ms-2 rounded-xl"
                      onClick={handleSuccessAlert}
                      disabled={isSubmitting}
                    />
                  </>
                }
              />
            </Row>
          </Form>
        )}
      </Formik>
      <AlertModal
        visible={successAlert}
        title={
          selectedTemplate
            ? saveFormat
              ? "Saved as My Template"
              : "Template Updated"
            : "Template Added"
        }
        description={
          selectedTemplate
            ? saveFormat
              ? "Template has been saved as My template successfully!"
              : "Template details have been updated successfully!"
            : "New template have been added successfully"
        }
        primaryBtnProps={{
          label: "Ok",
          onClick: () => handleSuccessAlert()
        }}
        closable
        onCancel={() => handleSuccessAlert()}
      />
    </>
  );
};

export default inject(stores => ({
  authStore: stores.store.authStore,
  templateStore: stores.store.templateStore,
  modalStore: stores.store.modalStore
}))(observer(TemplateForm));
