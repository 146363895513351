import { decorate, observable, runInAction } from "mobx";
import moment from "moment";
import { getAllContacts } from "service/contactService";
import {
  dailySms,
  monthlySms,
  onBoardingChecklist
} from "service/dashboardService";
import { getCostOfCampaign } from "service/gatewayService";

class ObservableDashboardtStore {
  dailySmsResponse = undefined;
  monthlySmsResponse = undefined;
  totalSmsResponse = undefined;
  checklist = undefined;

  //statics for cards (SMS & MMS)
  statics = {
    totalSmsCount: 0,
    totalMmsCount: 0,
    delivered: {
      sms: 0,
      mms: 0
    },
    recieved: {
      sms: 0,
      mms: 0
    },
    totalSpend: 0,
    contact: 0,
    balance: 0,
    blocked: {
      sms: 0,
      mms: 6
    },
    failed: {
      sms: 0,
      mms: 0
    },
    optOut: 0,
    rate: {
      sms: 0,
      mms: 0
    },
    expired: {
      sms: 0,
      mms: 0
    },
    sent: {
      sms: 0,
      mms: 0
    },
    queued: {
      sms: 0,
      mms: 0
    }
  };

  graphData = {
    sms: {
      time: [],
      count: [],
      delivered: [],
      recieved: [],
      failed: [],
      expired: [],
      sent: []
    },
    mms: {
      time: [],
      count: [],
      delivered: [],
      recieved: [],
      failed: [],
      expired: [],
      sent: []
    }
  };

  // reseting all values
  resetValues = () => {
    // totalSMSAborted
    // totalSMSBlocked
    // totalSMSDeleted
    // totalSMSDelievered
    // totalSMSDispatched
    // totalSMSExpired
    // totalSMSFailed
    // totalSMSQueued
    // totalSMSRecieved
    // totalSMSRejected
    // totalSMSScheduled
    // totalSMSUnknown
    // totalSmsSent
    // totalSpent
    // for sms
    this.graphData.sms = {
      time: [],
      count: [],
      aborted: [],
      blocked: [],
      deleted: [],
      delivered: [],
      dispatched: [],
      expired: [],
      failed: [],
      queued: [],
      recieved: [],
      rejected: [],
      scheduled: [],
      unknown: [],
      sent: [],
      spent: []
    };
    // for mms
    this.graphData.mms = {
      time: [],
      count: [],
      aborted: [],
      blocked: [],
      deleted: [],
      delivered: [],
      dispatched: [],
      expired: [],
      failed: [],
      queued: [],
      recieved: [],
      rejected: [],
      scheduled: [],
      unknown: [],
      sent: [],
      spent: []
    };
    // for cards
    this.statics.totalSmsCount = 0;
    this.statics.totalMmsCount = 0;
    this.statics.totalSpend = 0;
    this.statics.delivered = {
      sms: 0,
      mms: 0
    };
    this.statics.blocked = {
      sms: 0,
      mms: 0
    };
    this.statics.recieved = {
      sms: 0,
      mms: 0
    };
    this.statics.optOut = 0;
    this.statics.failed = {
      sms: 0,
      mms: 0
    };
    this.statics.expired = {
      sms: 0,
      mms: 0
    };
    this.statics.sent = {
      sms: 0,
      mms: 0
    };
    this.statics.queued = {
      sms: 0,
      mms: 0
    };
  };

  dailySms = async params => {
    let dailySmsResponse = await dailySms(params);
    try {
      runInAction(() => {
        this.resetValues();
        if (dailySmsResponse.data.smsStats.items.length) {
          dailySmsResponse.data.smsStats.items.map(val => {
            // for card
            this.statics.delivered.sms += val?.totalSMSDelievered || 0;
            this.statics.sent.sms += val?.totalSmsSent || 0;
            this.statics.recieved.sms += val?.totalSMSRecieved || 0;
            this.statics.failed.sms += val?.totalSMSFailed || 0;
            this.statics.blocked.sms += val?.totalSMSBlocked || 0;
            this.statics.expired.sms += val?.totalSMSExpired || 0;
            this.statics.queued.sms += val?.totalSMSQueued || 0;

            // total stats container
            this.statics.totalSmsCount +=
              val?.totalSMSDelievered +
              val?.totalSmsSent +
              val?.totalSMSFailed +
              val?.totalSMSExpired;
            this.statics.totalSpend += val?.totalSpent;

            // for graph
            this.graphData.sms.count.push(
              val?.totalSMSDelievered +
                val?.totalSmsSent +
                val?.totalSMSFailed +
                val?.totalSMSExpired
            );
            this.graphData.sms.time.push(moment(val.time).format("DD MMM"));
            this.graphData.sms.aborted.push(val?.totalSMSAborted || 0);
            this.graphData.sms.blocked.push(val?.totalSMSBlocked || 0);
            this.graphData.sms.deleted.push(val?.totalSMSDeleted || 0);
            this.graphData.sms.delivered.push(val?.totalSMSDelievered || 0);
            this.graphData.sms.dispatched.push(val?.totalSMSDispatch || 0);
            this.graphData.sms.expired.push(val?.totalSMSExpired || 0);
            this.graphData.sms.failed.push(val?.totalSMSFailed || 0);
            this.graphData.sms.queued.push(val?.totalSMSQueued || 0);
            this.graphData.sms.recieved.push(val?.totalSMSRecieved || 0);
            this.graphData.sms.rejected.push(val?.totalSMSRejected || 0);
            this.graphData.sms.scheduled.push(val?.totalSMSScheduled || 0);
            this.graphData.sms.unknown.push(val?.totalSMSUnknown || 0);
            this.graphData.sms.sent.push(val?.totalSMSSent || 0);
            this.graphData.sms.spent.push(val?.totalSpent || 0);
          });
        }

        if (dailySmsResponse.data.mmsStats.items.length) {
          dailySmsResponse.data.mmsStats.items.map(val => {
            // for cards
            this.statics.delivered.mms += val?.totalSMSDelievered || 0;
            this.statics.sent.mms += val?.totalSmsSent || 0;
            this.statics.recieved.mms += val?.totalSMSRecieved || 0;
            this.statics.failed.mms += val?.totalSMSFailed || 0;
            this.statics.blocked.mms += val?.totalSMSBlocked || 0;
            this.statics.expired.mms += val?.totalSMSExpired || 0;
            this.statics.queued.mms += val?.totalSMSQueued || 0;

            //total stats container
            this.statics.totalMmsCount +=
              val?.totalSMSDelievered +
              val?.totalSmsSent +
              val?.totalSMSFailed +
              val?.totalSMSExpired;
            this.statics.totalSpend += val?.totalSpent;

            // for graphs
            this.graphData.mms.count.push(
              val?.totalSMSDelievered +
                val?.totalSmsSent +
                val?.totalSMSFailed +
                val?.totalSMSExpired
            );
            this.graphData.mms.time.push(moment(val?.time).format("DD MMM"));
            this.graphData.mms.aborted.push(val?.totalSMSAborted || 0);
            this.graphData.mms.blocked.push(val?.totalSMSBlocked || 0);
            this.graphData.mms.deleted.push(val?.totalSMSDeleted || 0);
            this.graphData.mms.delivered.push(val?.totalSMSDelievered || 0);
            this.graphData.mms.dispatched.push(val?.totalSMSDispatch || 0);
            this.graphData.mms.expired.push(val?.totalSMSExpired || 0);
            this.graphData.mms.failed.push(val?.totalSMSFailed || 0);
            this.graphData.mms.queued.push(val?.totalSMSQueued || 0);
            this.graphData.mms.recieved.push(val?.totalSMSRecieved || 0);
            this.graphData.mms.rejected.push(val?.totalSMSRejected || 0);
            this.graphData.mms.scheduled.push(val?.totalSMSScheduled || 0);
            this.graphData.mms.unknown.push(val?.totalSMSUnknown || 0);
            this.graphData.mms.sent.push(val?.totalSMSSent || 0);
            this.graphData.mms.spent.push(val?.totalSpent || 0);
          });
        }
      });
    } catch (error) {
      this.dailySmsResponse = error;
    }
  };

  monthlySms = async params => {
    let monthlySmsResponse = await monthlySms(params);
    try {
      runInAction(() => {
        this.resetValues();
        if (monthlySmsResponse.data.smsStats.items.length) {
          monthlySmsResponse.data.smsStats.items.map(val => {
            // for card
            this.statics.delivered.sms += val.totalSMSDelievered || 0;
            this.statics.sent.sms += val.totalSmsSent || 0;
            this.statics.recieved.sms += val.totalSMSRecieved || 0;
            this.statics.failed.sms += val.totalSMSFailed || 0;
            this.statics.blocked.sms += val.totalSMSBlocked || 0;
            this.statics.expired.sms += val.totalSMSExpired || 0;
            this.statics.queued.sms += val.totalSMSQueued || 0;

            // total stats container
            this.statics.totalSmsCount +=
              val.totalSMSDelievered +
              val.totalSmsSent +
              val.totalSMSFailed +
              val.totalSMSExpired;
            this.statics.totalSpend += val.totalSpent;

            // for graph
            this.graphData.sms.count.push(
              val.totalSMSDelievered +
                val.totalSmsSent +
                val.totalSMSFailed +
                val.totalSMSExpired
            );
            this.graphData.sms.time.push(moment(val.time).format("DD MMM"));
            this.graphData.sms.aborted.push(val?.totalSMSAborted || 0);
            this.graphData.sms.blocked.push(val?.totalSMSBlocked || 0);
            this.graphData.sms.deleted.push(val?.totalSMSDeleted || 0);
            this.graphData.sms.delivered.push(val?.totalSMSDelievered || 0);
            this.graphData.sms.dispatched.push(val?.totalSMSDispatch || 0);
            this.graphData.sms.expired.push(val?.totalSMSExpired || 0);
            this.graphData.sms.failed.push(val?.totalSMSFailed || 0);
            this.graphData.sms.queued.push(val?.totalSMSQueued || 0);
            this.graphData.sms.recieved.push(val?.totalSMSRecieved || 0);
            this.graphData.sms.rejected.push(val?.totalSMSRejected || 0);
            this.graphData.sms.scheduled.push(val?.totalSMSScheduled || 0);
            this.graphData.sms.unknown.push(val?.totalSMSUnknown || 0);
            this.graphData.sms.sent.push(val?.totalSMSSent || 0);
            this.graphData.sms.spent.push(val?.totalSpent || 0);
          });
        }

        if (monthlySmsResponse.data.mmsStats.items.length) {
          monthlySmsResponse.data.mmsStats.items.map(val => {
            // for cards
            this.statics.delivered.mms += val.totalSMSDelievered || 0;
            this.statics.sent.mms += val.totalSmsSent || 0;
            this.statics.recieved.mms += val.totalSMSRecieved || 0;
            this.statics.failed.mms += val.totalSMSFailed || 0;
            this.statics.blocked.mms += val.totalSMSBlocked || 0;
            this.statics.expired.mms += val.totalSMSExpired || 0;
            this.statics.queued.mms += val.totalSMSQueued || 0;

            //total stats container
            this.statics.totalMmsCount +=
              val.totalSMSDelievered +
              val.totalSmsSent +
              val.totalSMSFailed +
              val.totalSMSExpired;
            this.statics.totalSpend += val.totalSpent;

            // for graphs
            this.graphData.mms.count.push(
              val.totalSMSDelievered +
                val.totalSmsSent +
                val.totalSMSFailed +
                val.totalSMSExpired
            );
            this.graphData.mms.time.push(moment(val.time).format("DD MMM"));
            this.graphData.mms.aborted.push(val?.totalSMSAborted || 0);
            this.graphData.mms.blocked.push(val?.totalSMSBlocked || 0);
            this.graphData.mms.deleted.push(val?.totalSMSDeleted || 0);
            this.graphData.mms.delivered.push(val?.totalSMSDelievered || 0);
            this.graphData.mms.dispatched.push(val?.totalSMSDispatch || 0);
            this.graphData.mms.expired.push(val?.totalSMSExpired || 0);
            this.graphData.mms.failed.push(val?.totalSMSFailed || 0);
            this.graphData.mms.queued.push(val?.totalSMSQueued || 0);
            this.graphData.mms.recieved.push(val?.totalSMSRecieved || 0);
            this.graphData.mms.rejected.push(val?.totalSMSRejected || 0);
            this.graphData.mms.scheduled.push(val?.totalSMSScheduled || 0);
            this.graphData.mms.unknown.push(val?.totalSMSUnknown || 0);
            this.graphData.mms.sent.push(val?.totalSMSSent || 0);
            this.graphData.mms.spent.push(val?.totalSpent || 0);
          });
        }
      });
    } catch (error) {
      this.monthlySmsResponse = error;
    }
  };

  totalSms = async () => {
    // let totalSmsResponse = await totalSms();
    const optOutParam = {
      page: 1,
      sortField: "updatedAt",
      sortOrder: "descend",
      isBlocked: true,
      isBlockedByUser: true
    };
    const smsRate = await getCostOfCampaign({ totalSms: 1 });
    const mmsRate = await getCostOfCampaign({ totalMMS: 1 });
    const optOut = await getAllContacts(optOutParam);
    try {
      runInAction(() => {
        this.statics.rate.sms = smsRate.data.rate || 0;
        this.statics.rate.mms = mmsRate.data.rate || 0;
        this.statics.optOut = optOut.total || 0;
      });
    } catch (error) {
      this.totalSmsResponse = error;
    }
    return this.statics;
  };

  getChecklistStats = async () => {
    const checklistData = await onBoardingChecklist();
    try {
      runInAction(() => {
        // this.checklist = Object.keys(checklistData.data).map(key => {
        //   return { id: key, status: checklistData.data[key] };
        // });
        this.checklist = checklistData.data;
      });
    } catch (error) {
      this.checklist = error;
    }
  };
}

decorate(ObservableDashboardtStore, {
  dailySmsResponse: observable,
  monthlySmsResponse: observable,
  totalSmsResponse: observable,
  dailySms: observable,
  monthlySms: observable,
  statics: observable,
  graphData: observable,
  checklist: observable
});
export default new ObservableDashboardtStore();
