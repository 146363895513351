import React, { useEffect, useMemo, useState } from "react";
import { inject, observer } from "mobx-react";
import _ from "lodash";
import moment from "moment";
// import { socket } from "utils/socket";
import { useLocation, useHistory } from "react-router-dom";
import { Tag, Row, Col, Tooltip, Typography, Divider } from "antd";
import { FilePdfOutlined, AudioOutlined, VideoCameraOutlined, FileImageOutlined } from "@ant-design/icons";
import AppTable from "components/AppTable/AppTable";
import AppDropdown from "components/AppDropdown/AppDropdown";
import { imageSupportList, videoSupportList, audioSupportList } from "common/utils/fileSupportList";
import { dateTimeFormatWithSeconds, timeFormat } from "constant/constant";
// import ExportModal from "components/History/Modal/ExportModal";
import ContactLog from "components/TargetGroup/ContactLog";
import FilterBar from "components/History/FilterBar";
import ScheduleMessage from "components/GatewayMessage/Modals/ScheduleMessage";
import { messageStatus } from "constant/commonList";
import AlertModal from "components/Modals/AlertModal";
import AppButton from "components/AppButton/AppButton";
import ExportNotificationModal from "./Modal/ExportNotificationModal";

let dropdownOptions = [
  { label: "Reuse", value: "reuse" },
  { label: "Reschedule", value: "reschedule" },
  { label: "Cancel", value: "cancel" }
];

const HistoryView = props => {
  const { gatewayStore, gatewayMmsStore, modalStore, messageStore, isMobile = false } = props;
  const location = useLocation();
  const [messageType, setMessageType] = useState(modalStore.isMms ? "mms" : "sms");
  const [contactLogVisible, setContactLogVisible] = useState({
    visible: false,
    contact: null
  });

  const history = useHistory();

  const { gatewaySMSList, getAllGatewaySMS, setSearch, total, rescheduleMessage, cancelScheduleMessage, getExportLogList } = useMemo(() => {
    if (modalStore.isMms) {
      return {
        ...gatewayMmsStore,
        gatewaySMSList: gatewayMmsStore.gatewayMMSList,
        getAllGatewaySMS: gatewayMmsStore.getAllGatewayMMS
      };
    } else {
      return gatewayStore;
    }
  }, [modalStore.isMms, gatewayMmsStore, gatewayStore]);

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [dateRange, setDateRange] = useState(undefined);
  const [dates, setDates] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [receiver, setReceiver] = useState("");
  const [refreshing, setRefreshing] = useState(false);

  const [tableData, setTableData] = useState([]);

  const [cantRescheduleAlert, setCantRescheduleAlert] = useState(false);
  const [rescheduleModal, setRescheduleModal] = useState(false);
  const [rescheduleSuccessAlert, setRescheduleSuccessAlert] = useState(false);
  const [selectedMessage, selectMessage] = useState(null);
  const [cancelScheduleAlert, setCancelScheduleAlert] = useState(false);
  const [cancelScheduleSuccessAlert, setCancelScheduleSuccessAlert] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);

  const [limitAlert, setLimitAlert] = useState(false);

  const [alertMessage, setAlertMessage] = useState("Something went wrong!");
  const [alertType, setAlertType] = useState("error");
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    setSearch(
      status || dateRange
        ? {
            status: status == "all" ? undefined : status ? status : undefined,
            from: dateRange ? dateRange[0] : undefined,
            to: dateRange ? dateRange[1] : undefined
          }
        : undefined
    );
    // if (status || dateRange) socket.disconnect();
    // else socket.connect();
  }, [status, dateRange]);

  const fetchSMSData = () => {
    getAllGatewaySMS({
      page: currentPage,
      receiver: receiver
    }).finally(() => {
      setLoading(false);
      setTableData(modalStore.isMms ? gatewayMmsStore.gatewayMMSList : gatewaySMSList);
    });
  };

  useEffect(() => {
    setLoading(true);
  }, [modalStore.isMms]);

  useEffect(() => {
    if (loading) fetchSMSData();
    const intervalId = setInterval(fetchSMSData, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, [modalStore.isMms, currentPage, dateRange, status, receiver, refreshing, gatewaySMSList, gatewayMmsStore.gatewayMMSList]);

  // useEffect(() => {
  //   socket.disconnect();
  // }, []);

  let columns = [
    {
      title: "Sender",
      key: "sender",
      render: message => {
        return (
          <span
            type={message.status == "received" && !isMobile ? "button" : "text"}
            onClick={() =>
              message.status == "received" &&
              !isMobile &&
              setContactLogVisible({
                visible: true,
                contact: message.sender
              })
            }
            className={message.status == "received" && !isMobile ? "text-decoration-underline" : ""}
          >
            {message.sender}
          </span>
        );
      },
      width: "120px"
    },
    {
      title: "Receiver",
      key: "receiver",
      render: message => {
        return (
          <>
            {message.contacts && message.contacts.length > 0 ? (
              message.contacts.map((contact, index) => {
                return (
                  <span key={index}>
                    {contact} {index !== message.contacts.length - 1 && ", "}
                  </span>
                );
              })
            ) : (
              <span
                type={message.status !== "received" && !isMobile ? "button" : "text"}
                onClick={() =>
                  message.status !== "received" &&
                  !isMobile &&
                  setContactLogVisible({
                    visible: true,
                    contact: message.receiver
                  })
                }
                className={message.status !== "received" && !isMobile ? "text-decoration-underline" : ""}
              >
                {message.receiver}
              </span>
            )}
          </>
        );
      },
      width: "120px"
    },
    {
      title: "Created On",
      key: "createdAt",
      render: message => {
        return <Typography.Text ellipsis>{moment(message.createdAt).format(dateTimeFormatWithSeconds)}</Typography.Text>;
      },
      width: "170px",
      ellipsis: true
    },
    {
      title: "Status",
      key: "status",
      width: "150px",
      render: message => {
        if (message.status == "failed" || message.status == "blocked") {
          return (
            <Tooltip
              placement="left"
              title={
                <>
                  Sorry, we couldn't sent this message!
                  <Divider className="my-2" />
                  REASON: {message.reason}
                </>
              }
              trigger="hover"
            >
              <div className="d-flex flex-row align-items-center">
                <Tag color="crimson">{message.status?.toUpperCase()}</Tag>
                <i className="fas fa-info-circle text-danger-color" />
              </div>
            </Tooltip>
          );
        } else {
          return (
            <Tag color={messageStatus.find(el => el.value === message.status)?.color || "blue"}>
              {messageStatus.find(el => el.value === message.status)?.label?.toUpperCase() || message.status?.toUpperCase()}
            </Tag>
          );
        }
      }
    }
  ];

  if (!isMobile) {
    columns.splice(2, 0, {
      title: "Message",
      key: "message",
      render: message => {
        return <Typography.Text ellipsis>{message.message}</Typography.Text>;
      },
      width: "300px",
      ellipsis: true
    });
    columns.splice(5, 0, {
      title: "Campaign",
      key: "bulk.name",
      className: "text-center",
      render: message => {
        return <>{message?.bulk ? "Yes" : "No"}</>;
      },
      width: "120px"
    });
    columns.splice(6, 0, {
      title: "Action",
      key: "action",
      className: "text-center",
      width: "120px",
      render: message => {
        if (message.status == "scheduled") {
          return <AppDropdown options={dropdownOptions} onClick={key => handleClick(key, message)} icon={<i className="fas fs-5 fa-ellipsis-v pe-auto text-muted" type="button" />} />;
        }
        if (message.status == "received") {
          return (
            <Tooltip placement="top" title="Reply">
              <i className="fas fa-paper-plane text-muted pe-auto fs-5" onClick={() => handleClick("reply", message)} type="button" />
            </Tooltip>
          );
        } else {
          return (
            <Tooltip placement="top" title="Reuse">
              <i className="fas fa-redo-alt text-muted pe-auto fs-5" type="button" onClick={() => handleClick("reuse", message)} />
            </Tooltip>
          );
        }
      }
    });
  }

  const handleClick = (key, record) => {
    switch (key) {
      case "reuse":
        if (isMobile) {
          modalStore.setQuickSMSData({
            sender: record.sender,
            receiver: record.receiver,
            message: record.message,
            subject: modalStore.isMms && record.subject ? record.subject : undefined
          });
          history.push("/quick-message");
        } else {
          if (modalStore.isMms) {
            modalStore.setIsMms(true);
            messageStore.phoneFrameImageUrl = undefined;
            messageStore.phoneFrameImageFileUrl = undefined;
            messageStore.setPhoneSubject(record.subject && record.subject);
          } else {
            modalStore.setIsMms(false);
          }
          modalStore.toggleModal("quickMessage", true);
          modalStore.setQuickSMSData({
            sender: record.sender,
            receiver: record.receiver,
            message: record.message,
            subject: modalStore.isMms && record.subject ? record.subject : undefined
          });
        }
        break;
      case "reschedule":
        if (moment().diff(record.scheduleAt, "minutes") >= -3) setCantRescheduleAlert(true);
        else {
          setRescheduleModal(true);
          selectMessage(record);
        }
        break;
      case "cancel":
        setCancelScheduleAlert(true);
        selectMessage(record);
        break;
      case "reply":
        if (modalStore.isMms) {
          modalStore.setIsMms(true);
          messageStore.phoneFrameImageUrl = undefined;
          messageStore.phoneFrameImageFileUrl = undefined;
          messageStore.setPhoneSubject(record.subject && record.subject);
        } else {
          modalStore.setIsMms(false);
        }
        modalStore.toggleModal("quickMessage", true);
        modalStore.setQuickSMSData({
          sender: record.receiver,
          receiver: record.sender,
          subject: modalStore.isMms && record.subject ? record.subject : undefined
        });
        break;
    }
  };

  const onTableRowExpand = (expended, record) => {
    setExpandedRowKeys(expended ? [record._id] : []);
  };

  const handleExport = async (values, setTab) => {
    // modalStore.toggleModal("showExportHistoryModal", true);
    if (values?.dates[1].diff(values?.dates[0], "days") > 30) {
      setLimitAlert(true);
      setTab("0");
      return;
    }
    let data = null;
    data = await getExportLogList({
      customEmail: values?.customEmail,
      status: values?.status,
      from: moment(values.dates[0]).format("YYYY-MM-DD"),
      to: moment(values.dates[1]).format("YYYY-MM-DD")
    });

    if (data?.success) {
      modalStore.setData("exportMessage", data.message);
      modalStore.setData("exportEmail", data.email);
      // modalStore.toggleModal("showExportNotification", true);
      setAlertMessage(data.message);
      setAlertType("Success");
      setTab("2");
      // setAlertOpen(true);
    } else {
      setAlertMessage(data.message);
      setAlertType("Error");
      setAlertOpen(true);
    }
  };

  const changeDates = (dates, dateString) => {
    setLoading(true);
    setCurrentPage(1);
    if (dateString[0] && dateString[1]) {
      setDates(dates);
      setDateRange(dateString);
    } else {
      setDates(undefined);
      setDateRange(undefined);
    }
  };

  const changeStatus = status => {
    setLoading(true);
    setCurrentPage(1);
    setStatus(status);
  };

  const changeMessageType = type => {
    setLoading(true);
    setMessageType(type);
    setCurrentPage(1);
    setReceiver(undefined);
    setDates(undefined);
    setStatus(undefined);
    setDateRange(undefined);
  };

  const handleSearch = e => {
    setLoading(true);
    setReceiver(e.target.value);
  };

  function rescheduleMsg(id, values) {
    rescheduleMessage(id, values).then(res => {
      if (res.status) {
        setRescheduleSuccessAlert(true);
      }
    });
    setRescheduleModal(false);
  }

  const cancelScheduleMsg = () => {
    setCancelLoading(true);
    cancelScheduleMessage(selectedMessage._id)
      .then(res => {
        if (res.status) setCancelScheduleSuccessAlert(true);
      })
      .finally(() => {
        setCancelLoading(false);
        setCancelScheduleAlert(false);
        selectMessage(null);
      });
  };

  const discardCanceling = () => {
    setCancelScheduleAlert(false);
    selectMessage(null);
  };

  return (
    <>
      <Row gutter={[20, 20]} className="p-2">
        <Col span={24}>
          <FilterBar
            changeDates={changeDates}
            changeStatus={changeStatus}
            changeMessageType={changeMessageType}
            messageType={messageType}
            status={status}
            dates={dates}
            exportBtn
            handleExport={() => modalStore.toggleModal("showExportNotification", true)}
            showSearch
            searchContact={handleSearch}
            contact={receiver}
            refreshBtn
            onRefresh={() => {
              setLoading(true);
              setRefreshing(!refreshing);
            }}
            btnProps={{
              disabled: loading || !modalStore.isMms ? gatewaySMSList.length == 0 : gatewayMmsStore.gatewayMMSList.length == 0
            }}
          />
        </Col>
        <Col span={24}>
          <AppTable
            loading={loading}
            columns={columns}
            // dataSource={tableData}
            dataSource={modalStore.isMms ? gatewayMmsStore.gatewayMMSList : gatewaySMSList}
            pagination={{
              onChange: page => {
                setLoading(true);
                setCurrentPage(page);
              },
              defaultPageSize: modalStore.isMms ? 10 : 50,
              defaultCurrent: 1,
              total: modalStore.isMms ? gatewayMmsStore.total : total,
              current: currentPage,
              className: "float-start",
              hideOnSinglePage: true,
              showQuickJumper: true,
              simple: isMobile
            }}
            secondary
            scroll={isMobile ? { x: 450, y: "calc(100vh - 350px)" } : { x: 800, y: 645 }}
            rowKey={record => record._id}
            expandRowByClick
            onExpand={onTableRowExpand}
            expandedRowKeys={expandedRowKeys}
            expandedRowRender={message => {
              return (
                <>
                  <Row gutter={20} align="middle" type="flex">
                    <Col span={24}>
                      {message.scheduleAt && (
                        <Row gutter={20} type="flex">
                          <Col md={4} span={24}>
                            <Typography.Title level={4} className="fw-bold" style={{ fontSize: "12px" }}>
                              Scheduled For
                            </Typography.Title>
                          </Col>
                          <Col md={20} span={24}>
                            <Typography.Text style={{ whiteSpace: "break-spaces" }}>{moment(message.scheduleAt).format(dateTimeFormatWithSeconds)}</Typography.Text>
                          </Col>
                        </Row>
                      )}
                      {message.unicode && (
                        <Row gutter={20} type="flex">
                          <Col md={4} span={24}>
                            <Typography.Title level={4} className="fw-bold" style={{ fontSize: "12px" }}>
                              Unicode
                            </Typography.Title>
                          </Col>
                          <Col md={20} span={24}>
                            <Typography.Text style={{ fontSize: "12px" }}>Yes</Typography.Text>
                          </Col>
                        </Row>
                      )}
                      {modalStore.isMms && message?.media && message?.media.length > 0 && (
                        <>
                          <Row gutter={20} align="middle" type="flex">
                            <Col md={4} span={24}>
                              <Typography.Title level={4} className="fw-bold" style={{ fontSize: "12px" }}>
                                Media File
                              </Typography.Title>
                            </Col>
                            {message.media.map((item, index) => {
                              return (
                                <Col md={4} span={6} key={index}>
                                  <a href={item.url} target="_blank">
                                    {imageSupportList.includes(item?.filetype) ? (
                                      <div
                                        style={{
                                          width: "50px",
                                          height: "50px"
                                        }}
                                        className="rounded-circle bg-white d-flex align-items-center justify-content-center"
                                      >
                                        <FileImageOutlined className="fs-4 text-info-color" />
                                      </div>
                                    ) : videoSupportList.includes(item?.filetype) ? (
                                      <div
                                        style={{
                                          width: "50px",
                                          height: "50px"
                                        }}
                                        className="rounded-circle bg-white d-flex align-items-center justify-content-center"
                                      >
                                        <VideoCameraOutlined className="fs-4 text-info-color" />
                                      </div>
                                    ) : audioSupportList.includes(item?.filetype) ? (
                                      <div
                                        style={{
                                          width: "50px",
                                          height: "50px"
                                        }}
                                        className="rounded-circle bg-white d-flex align-items-center justify-content-center"
                                      >
                                        <AudioOutlined className="fs-4 text-info-color" />
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          width: "50px",
                                          height: "50px"
                                        }}
                                        className="rounded-circle bg-white d-flex align-items-center justify-content-center"
                                      >
                                        <FilePdfOutlined className="fs-4 text-info-color" />
                                      </div>
                                    )}
                                  </a>
                                </Col>
                              );
                            })}
                          </Row>
                          <Divider />
                        </>
                      )}
                      {modalStore.isMms && message?.mediatest && message?.mediatest.length > 0 && (
                        <>
                          <Row gutter={20} align="middle" type="flex">
                            <Col md={4} span={24}>
                              <Typography.Title level={4} className="fw-bold" style={{ fontSize: "12px" }}>
                                Media File
                              </Typography.Title>
                            </Col>
                            {message.mediatest.map((item, index) => {
                              return (
                                <Col md={4} span={6} key={index}>
                                  <a href={item.url} target="_blank">
                                    {imageSupportList.includes(item?.filetype) ? (
                                      <div
                                        style={{
                                          width: "50px",
                                          height: "50px"
                                        }}
                                        className="rounded-circle bg-white d-flex align-items-center justify-content-center"
                                      >
                                        <FileImageOutlined className="fs-4 text-info-color" />
                                      </div>
                                    ) : videoSupportList.includes(item?.filetype) ? (
                                      <div
                                        style={{
                                          width: "50px",
                                          height: "50px"
                                        }}
                                        className="rounded-circle bg-white d-flex align-items-center justify-content-center"
                                      >
                                        <VideoCameraOutlined className="fs-4 text-info-color" />
                                      </div>
                                    ) : audioSupportList.includes(item?.filetype) ? (
                                      <div
                                        style={{
                                          width: "50px",
                                          height: "50px"
                                        }}
                                        className="rounded-circle bg-white d-flex align-items-center justify-content-center"
                                      >
                                        <AudioOutlined className="fs-4 text-info-color" />
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          width: "50px",
                                          height: "50px"
                                        }}
                                        className="rounded-circle bg-white d-flex align-items-center justify-content-center"
                                      >
                                        <FilePdfOutlined className="fs-4 text-info-color" />
                                      </div>
                                    )}
                                  </a>
                                </Col>
                              );
                            })}
                          </Row>
                          <Divider />
                        </>
                      )}
                      <Row gutter={20} type="flex">
                        {message?.bulk && (
                          <>
                            <Col md={4} span={24}>
                              <Typography.Title level={4} className="fw-bold" style={{ fontSize: "12px" }}>
                                Campaign Name
                              </Typography.Title>
                            </Col>
                            <Col md={20} span={24}>
                              <Typography.Text className="text-capitalize">{message?.bulk && message?.bulk.name}</Typography.Text>
                            </Col>
                          </>
                        )}
                        {modalStore.isMms && message?.subject && (
                          <>
                            <Col md={4} span={24}>
                              <Typography.Title level={4} className="fw-bold" style={{ fontSize: "12px" }}>
                                Subject
                              </Typography.Title>
                            </Col>
                            <Col md={20} span={24}>
                              <Typography.Text className="text-capitalize">{message?.subject}</Typography.Text>
                            </Col>
                          </>
                        )}
                        <Col md={4} span={24}>
                          <Typography.Title level={4} className="fw-bold" style={{ fontSize: "12px" }}>
                            Type
                          </Typography.Title>
                        </Col>
                        <Col md={20} span={24}>
                          <Typography.Text style={{ whiteSpace: "break-spaces" }}>
                            {message.status === "received"
                              ? "Reply"
                              : message?.bulk
                              ? message?.campaign_type === "notification"
                                ? "Notification"
                                : message?.campaign_type === "followup"
                                ? "Follow Up Message"
                                : "Promotional"
                              : message.workflow
                              ? "Workflow Action"
                              : modalStore.isMms
                              ? "Quick MMS"
                              : "Quick SMS"}
                          </Typography.Text>
                        </Col>
                      </Row>
                      <Row gutter={20} type="flex">
                        <Col md={4} span={24}>
                          <Typography.Title level={4} className="fw-bold" style={{ fontSize: "12px" }}>
                            Message
                          </Typography.Title>
                        </Col>
                        <Col md={20} span={24}>
                          <Typography.Paragraph
                            className="text-break"
                            ellipsis={{
                              rows: 3,
                              expandable: true,
                              symbol: "more"
                            }}
                            style={{ whiteSpace: "break-spaces" }}
                            copyable
                          >
                            {message.message}
                          </Typography.Paragraph>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {message.smsDetail && (
                    <>
                      <Divider />
                      <Row gutter={20} type="flex">
                        <Col md={4} span={12}>
                          <Typography.Title level={4} className="fw-bold" style={{ fontSize: "12px" }}>
                            Cost per {modalStore.isMms ? "MMS" : "SMS"}
                          </Typography.Title>
                        </Col>
                        <Col md={4} span={12}>
                          <Typography.Text>{message.status === "blocked" ? "N/A" : `${message.smsDetail.costpersms} cents`}</Typography.Text>
                        </Col>
                        <Col md={4} span={12}>
                          <Typography.Title level={4} className="fw-bold" style={{ fontSize: "12px" }}>
                            Total Character {messageType !== "mms" && "/ Total SMS"}
                          </Typography.Title>
                        </Col>
                        <Col md={4} span={12}>
                          <Typography.Text>
                            {message.status === "blocked" ? "N/A" : modalStore.isMms ? `${message.smsDetail.messagelength}` : `${message.smsDetail.messagelength} / ${message.smsDetail.smscount}`}
                          </Typography.Text>
                        </Col>
                        <Col md={4} span={12}>
                          <Typography.Title level={4} className="fw-bold" style={{ fontSize: "12px" }}>
                            Total Cost
                          </Typography.Title>
                        </Col>
                        <Col md={4} span={12}>
                          <Typography.Text>{message.status === "blocked" ? "N/A" : `${message.smsDetail.deductibleCredit} cents`}</Typography.Text>
                        </Col>
                      </Row>
                    </>
                  )}
                  {isMobile && (
                    <>
                      <Divider />
                      {message.status === "scheduled" ? (
                        <AppDropdown options={dropdownOptions} onClick={key => handleClick(key, message)} btnClassName="bg-light text-info-color rounded-pill" label="Select Action" />
                      ) : message.status === "received" ? (
                        <AppButton
                          light
                          className="bg-light text-info-color rounded-pill"
                          style={{ fontSize: "10px" }}
                          label="Send Reply"
                          onClick={() => {
                            modalStore.setQuickSMSData({
                              sender: message.receiver,
                              receiver: message.sender
                            });
                            history.push("/quick-message");
                          }}
                        />
                      ) : (
                        <AppButton
                          light
                          className="bg-light text-info-color rounded-pill"
                          style={{ fontSize: "10px" }}
                          label="Reuse Message"
                          onClick={() => {
                            modalStore.setQuickSMSData({
                              sender: message.sender,
                              receiver: message.receiver,
                              message: message.message
                            });
                            history.push("/quick-message");
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              );
            }}
          />
        </Col>
      </Row>
      {modalStore.showExportNotification && <ExportNotificationModal isHistory isMms={modalStore.isMms} handleExport={handleExport} />}
      <ContactLog visible={contactLogVisible.visible} handleClose={() => setContactLogVisible({ visible: false, contact: null })} number={contactLogVisible.contact} isMms={modalStore.isMms} />
      <ScheduleMessage
        isVisible={rescheduleModal}
        handleCancel={() => {
          setRescheduleModal(false);
          selectMessage(null);
        }}
        onSubmit={values => {
          let data = {
            scheduleAt: values.selectedDateTime
          };
          rescheduleMsg(values.bulk._id, data);
        }}
        modify
        formValues={selectedMessage}
      />
      <AlertModal
        visible={rescheduleSuccessAlert}
        title="Message Rescheduled"
        description="Message has been rescheduled successfully."
        primaryBtnProps={{
          onClick: () => setRescheduleSuccessAlert(false),
          label: "Ok"
        }}
        onCancel={() => setRescheduleSuccessAlert(false)}
      />
      <AlertModal
        visible={cancelScheduleAlert}
        title="Cancel Scheduled Message"
        description="Are you sure you want to cancel this scheduled message?"
        primaryBtnProps={{
          onClick: cancelScheduleMsg,
          label: "Ok"
        }}
        secondaryBtnProps={{
          onClick: discardCanceling,
          label: "Discard"
        }}
        loading={cancelLoading}
        secondaryBtn
        closable={false}
        onCancel={discardCanceling}
        warning
      />
      <AlertModal
        visible={cancelScheduleSuccessAlert}
        title="Cancelled Scheduled Message"
        description="Scheduled message has been cancelled successfully."
        primaryBtnProps={{
          onClick: () => setCancelScheduleSuccessAlert(false),
          label: "Ok"
        }}
        onCancel={() => setCancelScheduleSuccessAlert(false)}
      />
      <AlertModal
        visible={cantRescheduleAlert}
        title="Can't Reschedule Message"
        description="You can't reschedule this message."
        primaryBtnProps={{
          onClick: () => setCantRescheduleAlert(false),
          label: "Ok"
        }}
        onCancel={() => setCantRescheduleAlert(false)}
        error
      />
      <AlertModal
        visible={limitAlert}
        title="Date Limit Exceeds"
        description="You can export data for maximum of 30 days range."
        primaryBtnProps={{
          label: "Ok",
          onClick: () => setLimitAlert(false)
        }}
        closable
        onCancel={() => setLimitAlert(false)}
        error
      />
      <AlertModal
        visible={alertOpen}
        title={alertType}
        description={alertMessage}
        primaryBtnProps={{
          label: "Ok",
          onClick: () => {
            setAlertOpen(false);
            modalStore.toggleModal("showExportHistoryModal", false);
          }
        }}
        closable
        onCancel={() => {
          setAlertOpen(false);
          modalStore.toggleModal("showExportHistoryModal", false);
        }}
        error={alertType === "Error"}
      />
    </>
  );
};

export default inject(stores => ({
  gatewayStore: stores.store.gatewayStore,
  gatewayMmsStore: stores.store.gatewayMmsStore,
  modalStore: stores.store.modalStore,
  messageStore: stores.store.messageStore
}))(observer(HistoryView));
