import { runInAction, decorate, observable, toJS } from "mobx";
import {
  getAllContacts,
  updateBlockStatus,
  updateBulkBlockStatus,
  createContact,
  getContact,
  getContactBySearch,
  getExportContacts
} from "service/contactService";

class ObservableContactStore {
  contactList = [];
  total = 0;
  pageSize = 10;
  current = 1;
  search = "";
  sortField = "updatedAt";
  sortOrder = "descend";
  updateBlockStatusResponse = {};
  blockStatus = undefined;
  userBlockStatus = undefined;
  selectedContact = [];
  myContacts = [];
  contact = {};
  exportResponse = {};
  searchedContact = [];

  setSearchContacts = (item, clearStack = false) => {
    runInAction(() => {
      if (item && item.length > 0) {
        if (clearStack) this.searchedContact = [];
        this.searchedContact = [...item, ...toJS(this.searchedContact)];
      } else {
        this.searchedContact = [];
      }
    });
  };

  changeSorting = fieldObj => {
    runInAction(() => {
      const { field, sortOrder } = fieldObj;
      this.sortField = field;
      this.sortOrder = sortOrder;
    });
  };

  setSearch = search => {
    runInAction(() => {
      this.search = search;
      this.current = 1;
    });
  };

  setPage = current => {
    runInAction(() => {
      this.current = current;
    });
  };

  setPageSize = pageSize => {
    runInAction(() => {
      this.pageSize = pageSize;
      this.current = 1;
    });
  };

  setBlockStatus = status => {
    runInAction(() => {
      this.blockStatus = status;
    });
  };

  setUserBlockStatus = status => {
    runInAction(() => {
      this.userBlockStatus = status;
    });
  };

  /**
   * get all Users
   * @returns {Promise<[]>}
   */
  getAllContacts = async () => {
    const params = {
      page: this.current,
      pageSize: this.pageSize,
      sortField: this.sortField,
      sortOrder: this.sortOrder
    };
    if (this.blockStatus) params.isBlocked = this.blockStatus;
    if (this.userBlockStatus) params.isBlockedByUser = this.userBlockStatus;
    if (this.search) params.search = this.search;
    const getAllContactsResponse = await getAllContacts(params);
    try {
      runInAction(() => {
        this.contactList = getAllContactsResponse.items;
        this.pageSize = getAllContactsResponse.limit;
        this.current = getAllContactsResponse.current;
        this.total = getAllContactsResponse.total;
      });
    } catch (error) {
      runInAction(() => {
        this.contactList = error;
      });
    }
    return this.contactList;
  };
  getContactsForUser = async () => {
    const params = {};
    if (this.search) params.search = this.search;
    const getMyContactsResponse = await getAllContacts(params);
    try {
      runInAction(() => {
        this.myContacts = getMyContactsResponse.data;
      });
    } catch (error) {
      runInAction(() => {
        this.myContacts = error;
      });
    }
    return this.myContacts;
  };
  updateBlockStatus = async (number, blockData = {}) => {
    const updateBlockStatusResponse = await updateBlockStatus(
      number,
      blockData
    );
    try {
      runInAction(() => {
        this.updateBlockStatusResponse = updateBlockStatusResponse;
      });
      this.getAllContacts();
    } catch (error) {
      runInAction(() => {
        this.updateBlockStatusResponse = error;
      });
    }
    return this.updateBlockStatusResponse;
  };

  updateBulkBlockStatus = async bulkData => {
    const bulkBlockStatusResponse = await updateBulkBlockStatus(bulkData);
    try {
      runInAction(() => {
        this.updateBlockStatusResponse = bulkBlockStatusResponse;
      });
      this.getAllContacts();
    } catch (error) {
      runInAction(() => {
        this.updateBlockStatusResponse = error;
      });
    }
    return this.updateBlockStatusResponse;
  };

  createContact = async data => {
    const res = await createContact(data);
    return res;
  };

  selectContact = async contact => {
    runInAction(() => {
      this.selectedContact = contact
        ? JSON.parse(JSON.stringify(contact))
        : undefined;
    });
  };

  getContact = async number => {
    const res = await getContact(number);
    try {
      runInAction(() => {
        this.contact = res;
      });
    } catch (error) {
      runInAction(() => {
        this.contact = error;
      });
    }
    return this.contact;
  };

  getContactBySearch = async (params = {}) => {
    const res = await getContactBySearch(params);
    try {
      runInAction(() => {
        this.contact = res;
      });
    } catch (error) {
      runInAction(() => {
        this.contact = error;
      });
    }
    return this.contact;
  };

  getExportContacts = async params => {
    const response = await getExportContacts(params);
    try {
      runInAction(() => {
        this.exportResponse = response;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
        this.exportResponse = error;
      });
    } finally {
      return this.exportResponse;
    }
  };
}

decorate(ObservableContactStore, {
  contactList: observable,
  pageSize: observable,
  current: observable,
  total: observable,
  sortField: observable,
  sortOrder: observable,
  search: observable,
  updateBlockStatusResponse: observable,
  selectedContact: observable,
  myContacts: observable,
  contact: observable,
  exportResponse: observable,
  searchedContact: observable
});

export default new ObservableContactStore();
