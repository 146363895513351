import React, { useEffect, useState, useRef } from "react";
import { inject, observer } from "mobx-react";
import { Formik } from "formik";
import { Form, Spin, Icon, Row, Col, Card, Typography, Divider } from "antd";
import * as Yup from "yup";
import FormInput from "components/FormItems/FormInput";
import AppButton from "components/AppButton/AppButton";
import { senderid, broadcast, incoming, api } from "assets/images/special";

const antIcon = (
  <Icon type="loading" style={{ fontSize: 24, color: "white" }} spin />
);

const fontColor = {
  color: "#565656"
};

function Offer(props) {
  const { centsPerCredit = 4.5, modalStore, handleBuyNow } = props;

  const formikRef = useRef();
  const [sms, setSms] = useState(500);
  const [amount, setAmount] = useState(0);
  const [priceWithTax, setAmountWithTax] = useState(0);
  const TAX_PERCENTAGE = 10;

  useEffect(() => {
    setAmount(sms && ((sms * centsPerCredit) / 100).toFixed(2));
    setAmountWithTax(
      sms &&
        (((sms * centsPerCredit) / 100) * (1 + TAX_PERCENTAGE / 100)).toFixed(2)
    );
  }, [amount, sms]);

  return (
    <>
      <Row type="flex" justify="center" className="px-3" gutter={[24, 24]}>
        <Col span={24} className="text-center">
          <Typography.Title
            style={fontColor}
            level={3}
            className="text-uppercase text-center gotham-bold mt-4"
          >
            Special Offer
          </Typography.Title>
          <Typography.Text className="text-uppercase text-center gotham-bold fw-bold fs-4">
            {parseFloat(centsPerCredit)} Cents per SMS
          </Typography.Text>
        </Col>
        <Col xl={4} md={6} span={12}>
          <Card hoverable className="text-center rounded-xl h-100">
            <Typography.Title level={4} className="fw-bold fs-5">
              {" "}
              Free Sender ID{" "}
            </Typography.Title>
            <img className="img-fluid special-offer-img" src={senderid} />
          </Card>
        </Col>
        <Col xl={4} md={6} span={12}>
          <Card hoverable className="text-center rounded-xl h-100">
            <Typography.Title level={4} className="fw-bold fs-5">
              {" "}
              SMS Broadcast{" "}
            </Typography.Title>
            <img className="img-fluid special-offer-img" src={broadcast} />
          </Card>
        </Col>
        <Col xl={4} md={6} span={12}>
          <Card hoverable className="text-center rounded-xl h-100">
            <Typography.Title level={4} className="fw-bold fs-5">
              {" "}
              Free Incoming SMS{" "}
            </Typography.Title>
            <img className="img-fluid special-offer-img" src={incoming} />
          </Card>
        </Col>
        <Col xl={4} md={6} span={12}>
          <Card hoverable className="text-center rounded-xl h-100">
            <Typography.Title level={4} className="fw-bold fs-5">
              {" "}
              Reliable API{" "}
            </Typography.Title>
            <img className="img-fluid special-offer-img" src={api} />
          </Card>
        </Col>
        <Col span={24} className="text-center">
          <Formik
            initialValues={{ credit: 500 }}
            validationSchema={Yup.object().shape({
              credit: Yup.number()
                .required("Number is required!")
                .min(500, "Can not buy less than 500 credits")
                .max(250000, "Can not buy more than 250000 credits")
            })}
            innerRef={formikRef}
            onSubmit={(values, { setSubmitting }) => {
              modalStore.setIsMms(false);
              handleBuyNow({
                sms: values.credit,
                price: amount * 100,
                priceWithTax: priceWithTax * 100,
                centsPerSMS: centsPerCredit,
                specialPlan: true
              });
              setSubmitting(false);
            }}
          >
            {({ values, setFieldValue, isSubmitting, handleSubmit }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row gutter={[20, 20]} justify="center" type="flex">
                    <Col md={12} span={24} className="text-center">
                      <div className="d-flex justify-content-center align-items-center">
                        <Typography.Title level={4} className="mb-0 me-2">
                          No of Credits:
                        </Typography.Title>{" "}
                        <FormInput
                          name="credit"
                          value={values.credit}
                          onChange={e => {
                            setFieldValue("credit", e.target.value);
                            setSms(e.target.value);
                          }}
                          size="default"
                          inputProps={{
                            max: 250000,
                            min: 500
                          }}
                          placeholder="Enter Number"
                          containerClassname={`m-0`}
                          inputClassname="border-muted fw-bold text-grey rounded-pill"
                          type={"number"}
                        />
                      </div>
                      <div className="text-danger-color fs-6 fw-normal">
                        {values.credit < 500
                          ? `Minimum 500 credits`
                          : values.credit > 250000
                          ? `Maximum 250000 credits`
                          : ""}
                      </div>
                    </Col>
                    <Col span={24} className="text-center">
                      <AppButton
                        label={
                          !isSubmitting ? (
                            `Pay $${priceWithTax}`
                          ) : (
                            <Spin indicator={antIcon} className="px-2" />
                          )
                        }
                        className={"bg-dark-green fs-5 px-4 py-2 fw-bold"}
                        type="submit"
                        disabled={isSubmitting}
                      />
                      <p className="text-dark mt-3">
                        (include {TAX_PERCENTAGE}% GST)
                      </p>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Col>
      </Row>
      <Divider />
    </>
  );
}

export default inject(stores => ({
  userStore: stores.store.userStore,
  authStore: stores.store.authStore,
  modalStore: stores.store.modalStore
}))(observer(Offer));
