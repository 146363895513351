import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import loading from "assets/images/infinite-blue.svg";
import { unauthorizedRoutes } from "./mapping";
import { addHubspotScript, addMouseflowScript, removeHubspotScript, removeLiveChatScript } from "scripts";
import { Helmet } from "react-helmet";

function Unauthorized() {
  return (
    <Suspense
      fallback={
        <div className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
          <img src={loading} alt="loading" width="100px" />
        </div>
      }
    >
      <Switch>
        {unauthorizedRoutes.map(i => (
          <Route
            key={i.path}
            exact={i.exact}
            path={i.path}
            render={props => {
              addMouseflowScript(["/signup"].includes(i.path));
              removeLiveChatScript();
              i.path.includes("/optout/") ? removeHubspotScript() : addHubspotScript();
              !i?.stopPreview ? (document.title = `${i.title ? `${i.title} | ` : ""}Guni Online and Bulk SMS Gateway`) : null;
              return (
                <>
                  {i?.stopPreview && (
                    <Helmet>
                      <title> </title>
                      <meta property="og:url" content="https://app.gunisms.com.au/nonexistent-url" />
                    </Helmet>
                  )}
                  <i.Component {...props} />
                </>
              );
            }}
          />
        ))}
      </Switch>
    </Suspense>
  );
}

export default Unauthorized;
